import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'
import Typography from '@material-ui/core/Typography';
/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook,LocalStorage } from '../../lib-atomic-functions';
/* packages */
/* functions  */
import { PinUpdate } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';

/* CONSTANTS */
const RESETOBJECTS = CONFIG.application.account.objects;
/* CONSTANTS */

const reset = observer((props) => {

  const { token } = props.match.params;

  /* state */
  const { accountPinUpdateContext, accountPinUpdateMethod } = PinUpdate({CONFIG});
  /* state */

 
  let cardNumber;
  if(LocalStorage.getLS('cardNumber')) cardNumber = LocalStorage.getLS('cardNumber');


  const handlePinUpdate = async () => {
    
    let  request = {
        "pin":values.pin
       }
    
    await accountPinUpdateMethod({token, body:request});
    if (accountPinUpdateContext.data.invalidToken) return false;
   props.history.push('/u/landing-pin-change');
  }

  const handleReset = () => {
    const { history } = props;
  }

  const {
    values,
    onChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handlePinUpdate, RESETOBJECTS);

  const password = { ...RESETOBJECTS.pin, error: error.pin, value: values.pin, onChange };
  const password_confirm = { ...RESETOBJECTS.pin_confirm, error: error.pin_confirm, value: values.pin_confirm, onChange };

  const submitButton = { fullWidth: true }
  const failUpdate = { error: ["Unable to update your pin. Invalid reset token."] }

  return <form onSubmit={onSubmit} noValidate>
    { accountPinUpdateContext.data.invalidToken &&
      <Fragment>
      <Invalid {...failUpdate} />
      </Fragment>
    }
    
    <Inputs.Password {...password} /><br/> <br/>
    <Inputs.Password {...password_confirm} />
    <div>&nbsp;</div>
    <Buttons.Normal {...submitButton}>Submit</Buttons.Normal>
  </form>

})

export default reset;
