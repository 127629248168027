import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import LoginStore from './store';
/* stores */

const index = ({CONFIG}) => {
  const accountLoginContext  = useContext(LoginStore);
  const accountLoginMethod = async ({body,token}) => {
 
    accountLoginContext.setLoading({loading: true, status: false, data: false});
    CONFIG.application.account.api.headers.token = token;
    const fetch = await Fetch({
      url: CONFIG.application.account.api.endpoints.auth.url,
      config: {
        method: CONFIG.application.account.api.endpoints.auth.method,
        headers: CONFIG.application.account.api.headers,
        body
      }
    });
  
    accountLoginContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
   
  }

  return { accountLoginContext, accountLoginMethod }
}

export default index;
