import React, { useState,Fragment } from 'react'
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'
import ReCAPTCHA from "react-google-recaptcha";
/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import Typography from '@material-ui/core/Typography';
/* packages */

/* functions  */
import { AccountLogin, AccountDetail, CaptchaToken } from '../../app-module-account-container/functions'
/* functions  */
// google Analytics
import ReactGA from 'react-ga';

import CONFIG from '../config';
import { makeStyles } from '@material-ui/core/styles';
/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.stepOne.objects;
const LOGINFAILRESPONSE = CONFIG.application.account.responses.fail;
const ENVIRONMENT = CONFIG.environment;
const MEMBERSTATUS = CONFIG.setup.setup.thor.memberStatus[ENVIRONMENT];
/* CONSTANTS */

function initializeReactGA(props1) {
  if(props1){
    // console.log("props1",props1.traderId);
    var userId = props1.traderId;
  
  // const {traderId}= props1
  ReactGA.set({page:window.location.pathname})

  ReactGA.initialize('UA-151657300-1', {
    gaOptions: {
      userId: userId
    }});
  ReactGA.pageview(window.location.pathname);
}
}

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
}));

const login = observer(({props, handleNext, handleBack}) => {

  const { login } = LocalStorage.getLS();
  if (login) handleNext();

  /* state */
  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(true);
  const [captchaToken, setCaptchaToken] = useState("");
  const { accountLoginContext, accountLoginMethod } = AccountLogin({CONFIG});
  const { accountDetailContext, accountDetailMethod } = AccountDetail({CONFIG});
  const { captchaTokenContext, captchaTokenMethod } = CaptchaToken({CONFIG});
  /* state */
  var responses=""
  const callback = async (response) => {
    setCaptchaToken(response) 
   
    // await captchaTokenMethod({token:response})
    if(response){
      setVerified(false);
    }
  }

  var disable="";

  if (verified==false){
   
     disable=true;

  }else{
     disable=false;
  }
  const handleLogin = async () => {
    const { history } = props;
    await accountLoginMethod({body: JSON.stringify(values),token:captchaToken});
    await accountDetailMethod(accountLoginContext.data); // {token}


    if (accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:5"||accountLoginContext.data.developerErrorMessage=="Card Terminated. Please Contact the store↵"||accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:9"){
      LocalStorage.addLS('login', { ...accountLoginContext.data.traderDetails, username: values.username });
      props.history.push('/u/landing-registration-terminated');
    }

    if (accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:11"){
      LocalStorage.addLS('login', { ...accountLoginContext.data.traderDetails, username: values.username });
      props.history.push('/u/landing-registration-on-hold');
    }

    // console.log("accountLoginContext",accountLoginContext.data);
    if (accountLoginContext.data.invalidToken || accountDetailContext.data.invalidToken) return false;
    if (accountDetailContext.data.traderDetails) {

    
      const { emailAddress, memberStatus,statusId } = accountDetailContext.data.traderDetails;
      // const { emailAddress, memberStatus } = accountDetailContext.data;
      if (MEMBERSTATUS[statusId] === "Unregistered") {
        LocalStorage.addLS('login', { ...accountLoginContext.data, username: values.username });
        //props.history.push('/u/landing-non-registered');
        handleNext();
      } else if (MEMBERSTATUS[statusId] === "Pending") {
        LocalStorage.addLS('login', { ...accountLoginContext.data, emailAddress, pending: true, username: values.username });
        LocalStorage.addLS('emailAddress',  emailAddress);
        props.history.push('/u/landing-registration-pending');
      } else if (MEMBERSTATUS[statusId] === "OnHold") {
        LocalStorage.addLS('login', { ...accountLoginContext.data, username: values.username });
        props.history.push('/u/landing-registration-on-hold');
      } else if (MEMBERSTATUS[statusId] === "Terminated") {
        LocalStorage.addLS('login', { ...accountLoginContext.data, username: values.username });
        props.history.push('/u/landing-registration-terminated');
      } else if (MEMBERSTATUS[statusId] === "Registered") {
        LocalStorage.addLS('login', { ...accountLoginContext.data, username: values.username });
        LocalStorage.addLS('detail', accountDetailContext.data);
        props.history.push('/u/landing-login-logout');
      }
      initializeReactGA(accountDetailContext.data.traderDetails);
    }
  }

  const {
    values,
    onChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleLogin, LOGINOBJECTS);

  const username = { ...LOGINOBJECTS.username, error: error.username, value: values.username, onChange, autoComplete: "new-username" };
  const password = { ...LOGINOBJECTS.password, error: error.password, value: values.password, onChange,  autoComplete: "new-password"};
  const submitButton = { fullWidth: true }
  const failLogin = { error: [LOGINFAILRESPONSE] }

  var size="normal"
  if (window.screen.availWidth<=1000){
  
  size = "compact"
  }



  const classes = useStyles();
  return <form onSubmit={onSubmit} noValidate>
      <Typography style={{fontFamily: 'Fresh sans,Fresh,Arial,Helvetica,sans-serif',fontSize: "26px"}} style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" align="center" gutterBottom>
    <b>Register your card</b>
  </Typography>
  <br/>
  { accountLoginContext.data.errorCode=="AUTH:AUTH_GULD:19" &&
      <Fragment>
      <Invalid  /><p style={{fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif', color:'red'}}>You have reached the maximum attempts to login, you can try again in one hour.<br/><br/>If you need to check your balance immediately, you can contact our team on 0800 40 40 40</p>
      <br/>
      </Fragment>
    }
    
      { accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:7" &&
      <Fragment>
      <Invalid  /><p style={{fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif', color:'red'}}>This card has been closed<br/><br/>please contact 0800 40 40 40 if you need further assistance</p>
      <br/>
      </Fragment>
    }
    {/* { accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:3" &&
      <Fragment>
      <Invalid  /><p style={{fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif', color:'red'}}>Invalid card number or PIN</p>
      <br/>
      </Fragment>
    } */}
  { accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:3" &&
      <Fragment>
      <Invalid  /><p style={{fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif', color:'red'}}>{accountLoginContext.data.errorMessage}</p>
      <br/>
      </Fragment>
    }
     {accountLoginContext.data.invalidToken &&
      <Fragment>
      <Invalid  /><p style={{fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif', color:'red'}}>Invalid card number or PIN</p>
      <br/>
      </Fragment>
    }
    <Inputs.Text {...username} /><br/> 
    <Inputs.Password {...password} />
    <div>&nbsp;</div>
    
    <div style={{textAlign: "-webkit-center"}}>
    <ReCAPTCHA 
    sitekey="6Le6i7cUAAAAAAVvoOKjPrVP6XKNTJGYXM9DPJhO"
    //sitekey="6LfFxqceAAAAAI9Kkg_YxtzzkQjJ_RBH7rgHxCw-"
    onChange={callback}
    size={size}
  />
  </div>
    <br/>
    {/* <Buttons.Normal {...submitButton} >Next</Buttons.Normal> */}
    <Buttons.Normal {...submitButton} disabled={verified}>Next</Buttons.Normal>
    <br/>
    <br/>
    <br/>
    <Typography variant="caption" gutterBottom>
      <Link style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} to="/u/login">Already have an account? Login in here.</Link>
    </Typography>
  <br/>
  <br/>
  <br/>
    <img className={classes.paper} src="https://thq-mj-woolworths.s3.ap-southeast-2.amazonaws.com/assets/countdown/REGISTRATION+card+image.png"></img>
  </form>
  

})

export default login;
