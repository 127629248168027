import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";

/* mui */
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListIcon from "@material-ui/icons/List";
/* mui */

/* packages */
import {
  ModulePaperWrapper,
  ModulePaperCreditBalanceWrapper,
  ModulePaperBonusCurrentWrapper,
  ModulePaperBonusPreviousWrapper,
  ModulePaperDateWrapper,
  ModulePaperInfoWrapper,
} from "../../lib-atomic-design-section";
/* packages */

/* modules */
import AccountMenu from "../../app-module-account-menu/default";
import AccountProfileInfo from "../../app-module-account-profile-info/default";
import AccountBalanceInfo from "../../app-module-account-balance-info/default";
import AccountFooter from "../../app-module-account-footer/default";
import TandC from "../../app-module-account-tnc/default";
/* modules */
import { LocalStorage } from "../../lib-atomic-functions";
import CONFIG from "../config";
import { makeStyles } from "@material-ui/core/styles";

// google Analytics
import ReactGA from "react-ga";
/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LOGOSMALL = CONFIG.setup.assets.logo_small;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */
function initializeReactGA() {
  ReactGA.set({ page: window.location.pathname });

  ReactGA.initialize("UA-151657300-1");
  ReactGA.pageview(window.location.pathname);
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: "auto",
  },
  square: {
    width: 10,
    height: 10,
    color: "black",
  },
}));

const index = observer((props) => {
  const { login } = LocalStorage.getLS();
  const { userId } = login;
  const LS = LocalStorage.getLS();
  const { detail } = LS;
  // LocalStorage.removeLS();

  const userName = detail.traderDetails.firstName;
  const lastName = detail.traderDetails.lastName;
  const trader = detail.traderId;

  var res = userName.charAt(0);
  var last = lastName.slice(0, 11);

  const classes = useStyles();

  const linkButton = { size: "small" };

  initializeReactGA();
  const style = {
    width: "377px !important",
  };
  /* data props */
  const menuData = { name: "Vanessa McNab", props };

  return (
    <Fragment>
      <AccountMenu {...menuData} />

      <Container maxWidth="sm" style={{ marginBottom: "190px" }}>
        <Typography
          style={{ height: "87px", marginTop: "19px" }}
          align="center"
        >
          <img className={classes.paper} src={LOGO} />
        </Typography>
        <ModulePaperWrapper>
          <br />
          <br />
          <br />
          <br />
          <Typography
            style={{
              fontFamily: "open_sansregular,Helvetica,Arial,sans-serif",
            }}
            variant="h4"
            gutterBottom
          >
            How to top up your card
          </Typography>
          <br />
          <Typography
            style={{
              fontFamily: "open_sansregular,Helvetica,Arial,sans-serif",
            }}
            variant="body2"
          >
            You can save for Christmas by topping up your Christmas Club account
            directly from your bank account. Follow the instructions below to
            set up a one-off or automatic payment from your online banking
            portal.
          </Typography>

          <Typography
            style={{
              fontFamily: "open_sansregular,Helvetica,Arial,sans-serif",
            }}
            variant="body2"
          >
            <br />
            <strong>1. Log into your online banking</strong> <br />
            <span>
              Go to your online banking portal as you normally would when
              setting up a new one-off or automatic payment for a payee.
            </span>
            <br />
            <br />
            <strong>2. Select "Woolworths Christmas Club" as payee</strong>{" "}
            <br />
            <span>
              You can find us in your bank’s list of verified/registered
              companies when searching for “Woolworths Christmas Club”.
            </span>
            <br />
            <br />
            <strong>
              3. Enter the details that will show on the payee’s statement
            </strong>{" "}
            <br />
            <span>
              By providing the correct payment reference, we will be able to
              link your payment to your Christmas Club account.
            </span>
            <br />
            <br />
            &nbsp; &nbsp; &#9632; &nbsp; <b> Particulars:</b>{" "}
            <span style={{ fontWeight: "700", color: "#007837" }}>
              {res + last}
            </span>
            <br />
            &nbsp; &nbsp; &#9632; &nbsp;<b> Code:</b>{" "}
            <i>leave this field blank</i> <br />
            &nbsp; &nbsp; &#9632; &nbsp; <b> Reference:</b>{" "}
            <span style={{ fontWeight: "700", color: "#007837" }}>
              {trader}
            </span>
            <br />
            <br />
            <strong>4. Check that the payment details are correct</strong>{" "}
            <br />
            <span>
              This is super important. Unfortunately, we won’t be able to
              process your payment if you have not provided the correct account
              number as payment reference.
            </span>
            <br />
            <br />
            We recommend that you keep an eye on your Christmas Club balance and
            your bank account when you top up your Christmas Club account for
            the first time to be sure your payment has been processed correctly.
            In the unlikely case that your payment could not be processed, you
            will receive a refund from us to your bank account within 4 business
            days.
          </Typography>
          <br />
        </ModulePaperWrapper>
      </Container>
      <TandC {...props} />
      <AccountFooter {...props} />
    </Fragment>
  );
});

export default index;
