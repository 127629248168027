import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import DetailStore from './store';
/* stores */

const index = ({CONFIG}) => {
  
  const accountDetailContext  = useContext(DetailStore);
  const accountDetailMethod = async ({token}) => {
    
    accountDetailContext.setLoading({loading: true, status: false, data: false});
    CONFIG.application.account.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.account.api.endpoints.detail.url,
      config: {
        method: CONFIG.application.account.api.endpoints.detail.method,
        headers: CONFIG.application.account.api.headers
      }
    });

    accountDetailContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }
 

  return { accountDetailContext, accountDetailMethod }
}

export default index;
