import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListIcon from '@material-ui/icons/List';
/* mui */

/* packages */
import { ModulePaperWrapper,
  ModulePaperCreditBalanceWrapper,
  ModulePaperBonusCurrentWrapper,
  ModulePaperBonusPreviousWrapper,
  ModulePaperDateWrapper,
  ModulePaperInfoWrapper
 } from '../../lib-atomic-design-section';
/* packages */

/* modules */
import AccountMenu from '../../app-module-account-menu/default';
import AccountProfileInfo from '../../app-module-account-profile-info/default';
import AccountBalanceInfo from '../../app-module-account-balance-info/default';
import AccountFooter from '../../app-module-account-footer/default';
import TandC from '../../app-module-account-tnc/default';
/* modules */
import { LocalStorage } from '../../lib-atomic-functions';
import CONFIG from '../config';

// google Analytics
import ReactGA from 'react-ga';
/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LOGOSMALL = CONFIG.setup.assets.logo_small;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */
function initializeReactGA() {
 
  ReactGA.set({page:window.location.pathname})

  ReactGA.initialize('UA-151657300-1');
  ReactGA.pageview(window.location.pathname);

}
const index = observer((props) => {

  const { login  } = LocalStorage.getLS();
  const {userId  } = login;


  
  initializeReactGA();
const style ={
  width: "377px !important"
}
  /* data props */
  const menuData = { name: "Vanessa McNab", props }

  return <Fragment>

    <AccountMenu {...menuData} />

    <Container maxWidth="sm" style={{marginBottom: '190px'}}>

      <AccountProfileInfo />
 
  
      <AccountBalanceInfo {...props} />

      <br/>
      <div>&nbsp;</div>
      <br/>

      <br/>
      <div>&nbsp;</div>
      <br/>

    </Container>
    <TandC {...props}/>
    <AccountFooter {...props} />

  </Fragment>

})

export default index;
