import React, { useEffect, useState, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'
import Moment from 'react-moment';
import Grid from '@material-ui/core/Grid';
/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */
// addressfinder
import AddressFinderWidget2 from '../../app-module-account-address-finder/default/finder2';
import AddressFinderWidget1 from '../../app-module-account-address-finder/default/finder1';
/* packages */
import { Inputs, Buttons } from '../../lib-atomic';
import { FormHook } from '../../lib-atomic-functions';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* sections */
import Loader from '../loader';
/* sections */

/* functions  */
import { AccountDetailUpdate } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';
import { string } from 'prop-types';

/* CONSTANTS */
const ACCOUNTOBJECTS = CONFIG.application.account.objects;
/* CONSTANTS */

const account = ({account, props}) => {
  
  const traderDetails = account.traderDetails;

  const LS = LocalStorage.getLS();
  const { login } = LS;
  if (!login) return <Redirect to='/u/logout' />

  /* state */
  const { accountDetailUpdateContext, accountDetailUpdateMethod } = AccountDetailUpdate({CONFIG});
  /* state */
  
  const { titleId, firstName, lastName, genderId, birthDate, emailAddress, mobileNumber, physicalSuburb, phone2,physicalAddress1,physicalCity,physicalAddress2,physicalPostcode,externalRef,prefixMobileNumber,prefixPhone2, address1,address2, city,  postcode, suburb} = traderDetails;
  const {cardNumber  } = account;

  const [isPhysicalManual, setPhysicalManual] = useState(false);
  const [isPostal, setPostal] = useState(false);
  const [isPhysical, setPhysical] = useState(false);

  const [isPostalManual, setPostalManual] = useState(false);

  const [isPostalDifferent, setPostalDifferent] = useState(false);
  //for prod env
  // const titleOption = [
  //   { name: "Dr", value: "289" },
  //   { name: "Mx", value: "288" },
  //   { name: "Miss", value: "287" },
  //   { name: "Ms", value: "286" },
  //   { name: "Mrs", value: "285" },
  //   { name: "Mr", value: "284" }
  // ];

  //for dev env
  const titleOption = [
    { name: "Dr", value: "246" },
    { name: "Mx", value: "245" },
    { name: "Miss", value: "244" },
    { name: "Ms", value: "243" },
    { name: "Mrs", value: "242" },
    { name: "Mr", value: "241" },
    { name: "", value: "249" },
  ];
  const genderOption = [
    { name: "M", value: "1" },
    { name: "F", value: "2" },
    { name: "X", value: "3" }
  ]
  const homePhoneOption = [
    { name: "", value: "" },
    { name: "03", value: "03" },
    { name: "04", value: "04" },
    { name: "06", value: "06" },
    { name: "07", value: "07" },
    { name: "09", value: "09" }
  ]
  const mobilePhoneOption = [
    { name: "", value: "" },
    { name: "020", value: "020" },
    { name: "021", value: "021" },
    { name: "022", value: "022" },
    { name: "025", value: "025" },
    { name: "027", value: "027" },
    { name: "028", value: "028" },
    { name: "029", value: "029" }
  ]


  // const [isPhysical, setPhysical] = useState(false);

  ACCOUNTOBJECTS.prefixMobileNumber.options = mobilePhoneOption;
  ACCOUNTOBJECTS.prefixPhone2.options = homePhoneOption;
  // LocalStorage.addLS('error121', "");
if (mobileNumber=="  "){
    
    var mobileNumber1="";
   
    } 
    if (phone2=="  "){
    
     var phone21=""
    }

    if (titleId == null){
    
      var titleId_null=""
     }
 
  if(prefixMobileNumber!=null && prefixMobileNumber!="  "){
    
   
    var myPhoneNumber = mobilePhoneOption.filter((data) => data.value === prefixMobileNumber)[0];
    }else{
      
      myPhoneNumber =  homePhoneOption.filter((data) => data.value === "")[0];
    }
 
  if(prefixPhone2!=null &&prefixPhone2!="  "){
   
  var  myHomeNumber = homePhoneOption.filter((data) => data.value === prefixPhone2)[0];
  }else{
    
    myHomeNumber =  homePhoneOption.filter((data) => data.value === "")[0];
  }

  const myTitle = titleOption.filter((data) => data.value === String(titleId))[0];
  const myGender = genderOption.filter((data) => data.value == genderId)[0];

  
  /* intial values, options */
  ACCOUNTOBJECTS.titleId.options = titleOption;
  // ACCOUNTOBJECTS.titleId.initial = myTitle.value;
  ACCOUNTOBJECTS.firstName.initial = firstName;
  ACCOUNTOBJECTS.lastName.initial = lastName;
  ACCOUNTOBJECTS.prefixMobileNumber.initial = myPhoneNumber.value;
  
  ACCOUNTOBJECTS.prefixPhone2.initial = myHomeNumber.value;
 
  //ACCOUNTOBJECTS.birthDate.initial = birthDate;
  ACCOUNTOBJECTS.genderId.options = genderOption;
  ACCOUNTOBJECTS.genderId.initial = myGender.value;
  //ACCOUNTOBJECTS.emailAddress.initial = emailAddress;
  if(mobileNumber1==""){
    
    ACCOUNTOBJECTS.mobileNumber.initial = mobileNumber1;
  }else{
   
    ACCOUNTOBJECTS.mobileNumber.initial = mobileNumber;
  }

  if(phone21==""){
  
    ACCOUNTOBJECTS.phone2.initial = phone21;
  }else{
    
    ACCOUNTOBJECTS.phone2.initial = phone2;
  }

  if(titleId == null){
  
    ACCOUNTOBJECTS.titleId.initial = titleId_null;
  }else{
    
    ACCOUNTOBJECTS.titleId.initial = myTitle.value;
  }
 
  // ACCOUNTOBJECTS.phone2.initial = phone2;
  // ACCOUNTOBJECTS.oneCard.initial = externalRef;
  ACCOUNTOBJECTS.physicalSuburb.initial = physicalSuburb;
  ACCOUNTOBJECTS.physicalAddress1.initial = physicalAddress1;
  ACCOUNTOBJECTS.physicalCity.initial = physicalCity;
  ACCOUNTOBJECTS.physicalAddress2.initial = physicalAddress2;
  ACCOUNTOBJECTS.physicalPostCode.initial = physicalPostcode;

  ACCOUNTOBJECTS.address1.initial = address1;
  ACCOUNTOBJECTS.address2.initial = address2;
  ACCOUNTOBJECTS.city.initial = city;
  ACCOUNTOBJECTS.suburb.initial = suburb;
  ACCOUNTOBJECTS.postcode.initial = postcode;
  /* intial values, options */
  const errorMessage =[];
  const handleCancel = () => {
    props.history.push('/account/details');
  }

  const handleAccountUpdate = async () => {
    
    if (  values.address1 == null || values.postcode == null){

      values.address1 = " ";
      values.address2 = " ";
      values.suburb = " ";
      values.city = " ";
      values.postcode = " ";
    }
    
    if (  values.address1 == "" || values.postcode == ""){

      values.address1 = " ";
      values.address2 = " ";
      values.suburb = " ";
      values.city = " ";
      values.postcode = " ";
    }
    if (values.mobileNumber==""){
     
      values.mobileNumber= " ";
      values.prefixMobileNumber=" ";
    }
    if (values.phone2==""){
      
      values.phone2=" ";
      values.prefixPhone2=" ";
    }
    
    if (values.mobileNumber==" "&&values.phone2=="  "){
    
      LocalStorage.addLS('error11', "Please enter at least one phone number");
     

     return false;
    }
    if (values.mobileNumber==" "&&values.phone2==" "){
    
        LocalStorage.addLS('error11', "Please enter at least one phone number");
       

       return false;
      }
      LocalStorage.addLS('error11', "");
    const { token, userId } = login;
    const body = JSON.stringify(values);
    await accountDetailUpdateMethod({userId, token, body});

    if (accountDetailUpdateContext.data.outcomeCode == "CTD:PAP_UM:5") {
      
    LocalStorage.addLS('error121', "Onecard Number already exist");
   
    props.history.push('/account/details/edit');
    }else{
      LocalStorage.addLS('error121', "");
      
    props.history.push('/account/details');
  }
}

  const handlePostalAddress = (selected) => {
    setValues(values => ({
      ...values,
      address1: selected.address_line_1(),
      address2: selected.address_line_2(),
      suburb: selected.suburb(),
      city: selected.city(),
      postcode: selected.postcode()
    }));
    setPostal(true);
  }
  
  const handlePostalDifferent = (event) => {
    setPostalDifferent(event.target.checked);
    if (!event.target.checked) {
      values.address1 = values.physicalAddress1;
      values.address2 = values.physicalAddress2;
      values.suburb =  values.physicalSuburb;
      values.city = values.physicalCity;
      values.postcode = values.physicalPostCode;
    } else {
      values.address1 = "";
      values.address2 = "";
      values.suburb = "";
      values.city = "";
      values.postcode = "";
    }
  }

  const handlePostalManual = (event) => {
    setPostalManual(event.target.checked);
    setPostal(false);
  
    ACCOUNTOBJECTS.address1.disabled = !event.target.checked;
    ACCOUNTOBJECTS.address2.disabled = !event.target.checked;
    ACCOUNTOBJECTS.suburb.disabled = !event.target.checked;
    ACCOUNTOBJECTS.city.disabled = !event.target.checked;
    ACCOUNTOBJECTS.postcode.disabled = !event.target.checked;
  }

  const handlePhysicalManual = (event) => {

    setPhysicalManual(event.target.checked);
    setPhysical(false);
 
    ACCOUNTOBJECTS.physicalAddress1.disabled = !event.target.checked;
    ACCOUNTOBJECTS.physicalAddress2.disabled = !event.target.checked;
    ACCOUNTOBJECTS.physicalSuburb.disabled = !event.target.checked;
    ACCOUNTOBJECTS.physicalCity.disabled = !event.target.checked;
    ACCOUNTOBJECTS.physicalPostCode.disabled = !event.target.checked;

  }
  const handlePhysicalAddress = (selected) => {
  
   
    setValues(values => ({
      ...values,
      physicalAddress1: selected.address_line_1(),
      physicalAddress2: selected.address_line_2(),
      physicalSuburb: selected.suburb(),
      physicalCity: selected.city(),
      physicalPostCode: selected.postcode(),
     
     
    }));
    setPhysical(true);
  }

  const {
    setValues,
    values,
    onChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleAccountUpdate, ACCOUNTOBJECTS);


  const onBlur = (event) => {
   
    values.address1 = values.address1;
    values.address2 = values.address2;
    values.suburb =  values.suburb;
    values.city = values.city;
    values.postcode = values.postcode;
  
}


  const formtitle = { ...ACCOUNTOBJECTS.titleId, error: error.titleId, value: values.titleId, onChange };

  const formfirstName = { ...ACCOUNTOBJECTS.firstName, error: error.firstName, value: values.firstName, onChange };
  const formlastName = { ...ACCOUNTOBJECTS.lastName, error: error.lastName, value: values.lastName, onChange };
  // const card = { ...ACCOUNTOBJECTS.oneCard, error: error.oneCard, value: values.oneCard, onChange };
  //const formbirthDate = { ...ACCOUNTOBJECTS.birthDate, error: error.birthDate, value: values.birthDate, onChange };
  const formgender = { ...ACCOUNTOBJECTS.genderId, error: error.genderId, value: values.genderId, onChange };
  //const formemailAddress = { ...ACCOUNTOBJECTS.emailAddress, error: error.emailAddress, value: values.emailAddress, onChange };
  const formmobileNumber = { ...ACCOUNTOBJECTS.mobileNumber, error: error.mobileNumber, value: values.mobileNumber, onChange };
  const formphone1 = { ...ACCOUNTOBJECTS.phone2, error: error.phone2, value: values.phone2, onChange };
  const submitButton = { fullWidth: true };
  const cancelButton = { fullWidth: true, onClick: handleCancel };
  const pre_mobileNumber = { ...ACCOUNTOBJECTS.prefixMobileNumber, error: error.prefixMobileNumber, value: values.prefixMobileNumber, onChange };
  const pre_home = { ...ACCOUNTOBJECTS.prefixPhone2, error: error.prefixPhone2, value: values.prefixPhone2, onChange };
  //addressField
  const address12 = { ...ACCOUNTOBJECTS.address1, error: error.address1, value: values.address1, onChange };
  const address123 = { ...ACCOUNTOBJECTS.address2, error: error.address2, value: values.address2, onChange };
  const suburb12 = { ...ACCOUNTOBJECTS.suburb, error: error.suburb, value: values.suburb, onChange };
  const city12 = { ...ACCOUNTOBJECTS.city, error: error.city, value: values.city, onChange };
  const postcode12 = { ...ACCOUNTOBJECTS.postcode, error: error.postcode, value: values.postcode, onChange };

  const physicalAddress12 = { ...ACCOUNTOBJECTS.physicalAddress1, error: error.physicalAddress1, value: values.physicalAddress1, onChange, onBlur };
  const physicalAddress13 = { ...ACCOUNTOBJECTS.physicalAddress2, error: error.physicalAddress2, value: values.physicalAddress2, onChange, onBlur };
  const physicalSuburb1 = { ...ACCOUNTOBJECTS.physicalSuburb, error: error.physicalSuburb, value: values.physicalSuburb, onChange, onBlur };
  const physicalCity1 = { ...ACCOUNTOBJECTS.physicalCity, error: error.physicalCity, value: values.physicalCity, onChange, onBlur };
  const physicalPostCode1 = { ...ACCOUNTOBJECTS.physicalPostCode, error: error.physicalPostCode, value: values.physicalPostCode, onChange, onBlur };
  const physicalmanual = { name: "physicalmanual", initial: "", label: "I want to enter my address manually", helperText: false, rules: false, error: error.physicalmanual, value: values.physicalmanual, onChange: handlePhysicalManual }; 

  const physicalAddressData = { handle: handlePhysicalAddress, props }
  const postalAddressData = { handle: handlePostalAddress, props }
  const checkPhysicalAddress = ["physicalAddress1", "physicalCity", "physicalPostCode", "physicalSuburb"]
  const isPhysicalAddressExist = checkPhysicalAddress.filter(data => data in error)[0] ? true : false;
  const postalmanual = { name: "postalmanual", initial: "", label: "I want to enter my address manually", helperText: false, rules: false, error: error.postalmanual, value: values.postalmanual, onChange: handlePostalManual };
  const postaldifferent = { name: "postaldifferent", initial: "", label: "My postal address is different", helperText: false, rules: false, error: error.postaldifferent, value: values.postaldifferent, onChange: handlePostalDifferent };
  
  
  const failPhysicalAddress = { error: ["Please enter your Address"] }
  const {error11, error121} = LocalStorage.getLS();
 
  return <form onSubmit={onSubmit} noValidate>
     

    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontSize:"16px"}} variant="caption" noWrap>
      <small>Date of birth</small>
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontSize:"16px"}} variant="subtitle2" gutterBottom>
      <Moment format="DD/MM/YYYY">{birthDate}</Moment>
    </Typography>
   
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontSize:"16px"}} variant="caption" noWrap>
      <small>Card number</small>
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontSize:"16px"}} variant="subtitle2" noWrap>
    {cardNumber}
    </Typography>
    <br/>


    <Inputs.Select style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...formtitle} />
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...formfirstName} />
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...formlastName} />
    { error121 &&
      <Fragment>
       
       <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif', color:"red"}} variant="body2" gutterBottom>
    {error121}
    </Typography>
      </Fragment>
    }
    {/* <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...card} inputProps={{maxLength: 13,}}/> */}
    { /* <Inputs.Text {...formbirthDate} /> */ }
    <Inputs.Select style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...formgender} />
    { /* <Inputs.Text {...formemailAddress} /> */ }
    
  
    <br/>
    { error11 &&
      <Fragment>
       
       <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif', color:"red"}} variant="body2" gutterBottom>
    {error11}
    </Typography>
      </Fragment>
    }
    
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      Home phone
    </Typography>
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item>
        <Inputs.Select {...pre_home} />
      </Grid>
      <Grid item xs zeroMinWidth>
        <Inputs.Text {...formphone1} inputProps={{maxLength: 7}}/>
      </Grid>
    </Grid>
    <br/>
   
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      Mobile
    </Typography>
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item>
        <Inputs.Select {...pre_mobileNumber} />
      </Grid>
      <Grid item xs zeroMinWidth>
        <Inputs.Text {...formmobileNumber}   inputProps={{maxLength: 10,}}/>
      </Grid>
    </Grid>



    {/* <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...formmobileNumber} /> */}
    {/* <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...formphone1} /> */}
    {/* <Inputs.Text {...physicalAddress12} />*/}

    <br/>
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      Physical address
    </Typography>

    {/* { isPhysicalAddressExist &&
      <Fragment>
      <Invalid {...failPhysicalAddress} />
      </Fragment>
    } */}

    { !isPhysicalManual &&
    <AddressFinderWidget2 {...physicalAddressData} />
    }

    <Inputs.Checkbox  {...physicalmanual} />
    <br/>
  
{ !isPhysicalManual && <div>
  <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...physicalAddress12} disabled/>
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...physicalAddress13}disabled />
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...physicalSuburb1}disabled />
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...physicalCity1}disabled />
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...physicalPostCode1} disabled/>
    </div>
  }

{ isPhysicalManual && <div>
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...physicalAddress12} />
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...physicalAddress13} />
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...physicalSuburb1} />
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...physicalCity1} />
    <Inputs.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...physicalPostCode1} />
    </div>
  }

<Inputs.Checkbox {...postaldifferent} />
    {/* postal address */}
   
   
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      Postal address
    </Typography>

    { !isPostalManual &&
    <AddressFinderWidget1 {...postalAddressData} />
    }

    <Inputs.Checkbox {...postalmanual} />

    <br/>
    <br/>

    { isPostalManual &&
    <Fragment>
    <Inputs.Text {...address12} />
    <Inputs.Text {...address123} />
    <Inputs.Text {...suburb12} />
    <Inputs.Text {...city12} />
    <Inputs.Text {...postcode12} />
    <br/>
    <br/>
    </Fragment>
    }

    { !isPostalManual &&
    <Fragment>
    <Inputs.Text {...address12} disabled/>
    <Inputs.Text {...address123} disabled/>
    <Inputs.Text {...suburb12} disabled/>
    <Inputs.Text {...city12} disabled/>
    <Inputs.Text {...postcode12} disabled/>
    <br/>
    <br/>
    </Fragment>
    }
    
    
    



    {/* <AddressFinderWidget2  props={{physicalAddress12}} physicalCity={{physicalCity1}}  zip={{physicalPostCode1}} {...physicalAddress12} values={values} />  */}
    <div>&nbsp;</div>
    <Buttons.Normal style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...submitButton}>Update</Buttons.Normal>
    <div>&nbsp;</div>
    <Buttons.Text style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...cancelButton}>Cancel</Buttons.Text>


  </form>

}

export default account;
