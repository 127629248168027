import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import BalanceStore from './store';
/* stores */

const index = ({CONFIG}) => {
  const accountBalanceContext  = useContext(BalanceStore);
  const accountBalanceMethod = async ({token}) => {
   
    accountBalanceContext.setLoading({loading: true, status: false, data: false});
    CONFIG.application.account.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.account.api.endpoints.balance.url,
      config: {
        method: CONFIG.application.account.api.endpoints.balance.method,
        headers: CONFIG.application.account.api.headers
      }
    });
    accountBalanceContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { accountBalanceContext, accountBalanceMethod }
}

export default index;
