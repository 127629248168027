import React, { Fragment, useEffect, useContext, useState } from 'react'

/* mui */
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
/* mui */

import AccountFooterMax from '../../app-module-account-footer-max/default';
import TandCMax from '../../app-module-account-tnc-max/default';
import AccountFooter from '../../app-module-account-footer/default';
import TandC from '../../app-module-account-tnc/default';
/* sections */
import ResetTitle from './title';
//import ResetSubTitle from './subtitle';
import ResetNav from './nav';
import LoginForm from '../form/login';
import AccountForm from '../form/account';
/* sections */

const getSteps = () => {
  return ['Register Card', 'Account Details'];
}

const stepper = props => {

  /* mui */
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0);
  }

  const stepOne = activeStep === 0 ? true : false;
  const stepTwo = activeStep === 1 ? true : false;
  /* mui */

  /* data props */
  const stepsData = { props, handleNext, handleBack }
  /* data props */

  return <Fragment>

    {/* <Stepper activeStep={activeStep} alternativeLabel>
      { steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      )) }
    </Stepper> */}
    

    { stepOne && <LoginForm {...stepsData} /> }

    { stepTwo && <AccountForm {...stepsData} /> }

  
  </Fragment>

}

export default stepper;
