import React from 'react';
import { Link } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import FaceIcon from '@material-ui/icons/Face';
import ListIcon from '@material-ui/icons/List';
import MoneyIcon from '@material-ui/icons/Money';
import ExitToApp from '@material-ui/icons/ExitToApp';

import CONFIG from '../config';


/* CONSTANTS */
const LOGOSMALL = CONFIG.setup.assets.logo_small;
const LOGOWORD = CONFIG.setup.assets.logo_word;
/* CONSTANTS */

const style={
  backgroundColor: '#fff'
}

const useStyles = makeStyles(theme => ({
  custstyle:{
    backgroundColor: '#fff !important'

  },

mobileView:{
  width: '450px !important',
},

  grow: {
    flexGrow: 1,
    backgroundColor: '#fff'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  imageIcon: {
    width: 20,
    height: 20,
  }
}));

export default function PrimarySearchAppBar({name, props}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const handleLinkBalance = () => {
    props.history.push('/account/balance');
  }

  const handleLinkTransaction = () => {
    props.history.push('/account/transaction');
  }

  const handleLinkProfile = () => {
    props.history.push('/account/details');
  }

  const handleLinkLogout = () => {
    props.history.push('/u/logout');
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.custstyle}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
     className={classes.mobileView}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}

    >
      <MenuItem onClick={handleLinkBalance} >
        <IconButton aria-label="Balances display" color="inherit">
        <img src=" https://mj-woolworths-uat.tr4ns.com/assets/countdown/Picture5.jpg" ></img>
        </IconButton>
        <a style={{ cursor: 'pointer',}}>Balance</a>
      </MenuItem>
      <MenuItem onClick={handleLinkTransaction}>
        <IconButton aria-label="Transaction list" color="inherit">
        <img className={classes.imageIcon} src="https://mj-woolworths-uat.tr4ns.com/assets/countdown/Picture3.jpg" ></img>
        </IconButton>
        <p>Transactions</p>
      </MenuItem>
      <MenuItem onClick={handleLinkProfile}>
        <IconButton aria-label="Profie link" color="inherit">
        <img src="https://thq-mj-woolworths-uat.s3-ap-southeast-2.amazonaws.com/assets/countdown/sign_in_register_icon.svg" style={{height:'23px'}}></img>
        </IconButton>
        <p>Account</p>
      </MenuItem>
      <MenuItem onClick={handleLinkLogout}>
        <IconButton aria-label="Profie link" color="inherit" style={{color: '#007837',fontSize: '30'}}>
          <ExitToApp />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow} >
      <AppBar position="static"  className={classes.custstyle}>
        <Toolbar>
        <a href="https://www.woolworths.co.nz/ "><img src={LOGOWORD} width="200" /></a>
          {/*
          <Typography className={classes.title} variant="h6" noWrap>
            <small>{ name }</small>
          </Typography>
          */}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>

            <IconButton aria-label="balance" style={{color: '#007837',fontSize: '30'}} onClick={handleLinkBalance}>
              {/* <MoneyIcon /> */}
              <img src=" https://mj-woolworths-uat.tr4ns.com/assets/countdown/Picture5.jpg" ></img>
            </IconButton>

            <a style={{cursor:'pointer'}} onClick={handleLinkBalance}><p style={{color:"#007837", }}>Balance</p></a>
            <IconButton aria-label="transaction" style={{color: '#007837',fontSize: '30'}} onClick={handleLinkTransaction}>
              {/* <ListIcon /> */}
              <img src="https://mj-woolworths-uat.tr4ns.com/assets/countdown/Picture3.jpg" ></img>
            </IconButton>

            <a style={{cursor:'pointer'}} onClick={handleLinkTransaction}><p style={{color:"#007837"}}>Transactions</p></a>
            <IconButton aria-label="profile" style={{color: '#007837',fontSize: '30'}} onClick={handleLinkProfile}>
              {/* <FaceIcon /> */}
              <img src="https://thq-mj-woolworths-uat.s3-ap-southeast-2.amazonaws.com/assets/countdown/sign_in_register_icon.svg" style={{height:'23px'}}></img>
            </IconButton>

            <a style={{cursor:'pointer'}} onClick={handleLinkProfile}><p style={{color:"#007837"}}>Account</p></a>
            <IconButton aria-label="profile" style={{color: '#007837',fontSize: '30'}}  onClick={handleLinkLogout}>
              <ExitToApp />
            </IconButton>

            <a style={{cursor:'pointer'}} onClick={handleLinkLogout}><p style={{color:"#007837"}}>Logout</p></a>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu }
      {renderMenu}
    </div>
  );
}
