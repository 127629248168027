import React from 'react';

/* mui */
import Container from '@material-ui/core/Container';
/* mui */

import Imports from './imports';
import Header from './header';
import Footer from './footer';

import appConf from '../../components/app-conf';
const Themes = appConf[process.env.REACT_APP_CLIENT].themes;

const thqLanding = props => {
  return <Themes>
    <Container maxWidth="xs">
      <Imports {...props} />
    </Container>
  </Themes>
}

export default thqLanding;
