import React from 'react'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

const ttile = props => {
  return <Typography style={{fontFamily: 'Fresh sans,Fresh,Arial,Helvetica,sans-serif',fontSize: "26px"}} style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" align="center" gutterBottom>
    <b>Login to your account</b>
  </Typography>
}

export default ttile;
