import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import TransactionStore from './store';
/* stores */
// test
// moduleCode: "IjFDYT22DHvwesvs",
// live
// moduleCode: "gLBs36QsZ",
const index = ({CONFIG}) => {
  
  const accountTransactionContext  = useContext(TransactionStore);
  
  const accountTransactionMethod = async ({token, query}) => {
   
    const header =   {
    'Content-Type': 'application/json',
    'moduleCode': "gLBs36QsZ",
    'Authorization': token,
  }
    accountTransactionContext.setLoading({loading: true, status: false, data: false});
    CONFIG.application.account.api.headers.Authorization = token;


 

    const fetch = await Fetch({
      url: CONFIG.application.account.api.endpoints.transaction.url+query,
      config: {
        method: CONFIG.application.account.api.endpoints.transaction.method,
        headers: header
      }
    });
   
    accountTransactionContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { accountTransactionContext, accountTransactionMethod }
}

export default index;
