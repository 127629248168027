const isRequired = (data) =>{
  
  const value = data.value.trim();
  const config = data.config;
  const limit = config.minmax_limit;
  const suffix = config.minmax_suffix;
  const title = data.config.label2;
  const nullAllow = config.allow_null ? true : false;
  if (nullAllow && value.length < 1) return true;
  return (value.length == limit) ? true : "Please enter your " + limit + " digit " + title + suffix;
}

export default isRequired;
