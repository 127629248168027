import React from 'react'
import { Link } from 'react-router-dom';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModuleWrapperMain, ModuleWrapperFull } from '../../lib-atomic-design-section';
/* packages */

const nav = props => {
  return <ModuleWrapperMain>
  <ModuleWrapperFull>
    <Typography variant="caption" gutterBottom>
      <Link style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} to="/u/pin-reset">Forgot your Password?</Link>
    </Typography>
  </ModuleWrapperFull>
  <ModuleWrapperFull>
    <Typography variant="caption" gutterBottom>
      <Link style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} to="/u/registration">Don't have an account? Register here.</Link>
    </Typography>
  </ModuleWrapperFull>
  </ModuleWrapperMain>
}

export default nav;
