import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* packages */
import { Inputs, Buttons } from '../../lib-atomic';
/* packages */
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
import CONFIG from '../config';

/* CONSTANTS */
// const ACCOUNTOBJECTS = CONFIG.application.account.objects;
/* CONSTANTS */



const account = observer(({handle, props, physicalCity, zip, values}) => {





  let widget = null;
  const addressFinder2 = React.createRef();

  /* state */
  useEffect(() => {
    var script = document.createElement('script');
    script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
    script.async = true;
    script.onload = handleLoadWidget
    document.body.appendChild(script);
  }, []);
  /* state */


  const handleLoadWidget = () => {
    widget = new window.AddressFinder.Widget(
      document.getElementById('addressFinder2'),
      '9H6QCRML43EPJDWXFNTV',
      'NZ'
    );
    widget.on('result:select', (fullAddress, metaData) => {
      const selected = new window.AddressFinder.NZSelectedAddress(fullAddress, metaData);
      handle(selected);
      // this.address_line_1.current.value = selected.address_line_1()
      // this.address_line_2.current.value = selected.address_line_2()
      // this.suburb.current.value = selected.suburb()
      // this.city.current.value = selected.city()
      // this.postcode.current.value = selected.postcode()
    });
  }
  

  var add;
  const fun = (fun) => {

    fun.persist();
    values = {[fun.target.name]: fun.target.value}
  
  }
  var address
  if (physicalCity==undefined||""){
    address= <Inputs.Text  type="search" id='addressFinder2' className='formInput' placeholder="Find address here..." ref={addressFinder2}  />
      
    }else{
    
    
    const valu = props.physicalAddress12.initial;
    const valu1 = physicalCity.physicalCity1.initial;
    const valu2 = zip.physicalPostCode1.initial;
    
     add = valu+valu1+valu2;
    
    const addre = { value:values.physicalAddress1, rules:false}
      address =  <Inputs.Text ref={addressFinder2} type="search" id='addressFinder2' className='formInput' placeholder="Find address here..." {...addre} onChange={fun}/>
    }
  
  return address

})



export default account;

