import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import DetailUpdateStore from './store';
/* stores */

const index = ({CONFIG}) => {
  
  const accountDetailUpdateContext  = useContext(DetailUpdateStore);
  const accountDetailUpdateMethod = async ({userId, token, body}) => {
    accountDetailUpdateContext.setLoading({loading: true, status: false, data: false});
    CONFIG.application.account.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.account.api.endpoints.update.url,
      config: {
        method: CONFIG.application.account.api.endpoints.update.method,
        headers: CONFIG.application.account.api.headers,
        body
      }
    });
    accountDetailUpdateContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { accountDetailUpdateContext, accountDetailUpdateMethod }
}

export default index;
