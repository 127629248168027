import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import CaptchaStore from './store';
/* stores */

const index = ({CONFIG}) => {
  const captchaTokenContext  = useContext(CaptchaStore);
  const captchaTokenMethod = async ({body}) => {

    captchaTokenContext.setLoading({loading: true, status: false, data: false});
    const fetch = await Fetch({
      url: CONFIG.application.account.api.endpoints.captcha.url + body,
      config: {
        method: CONFIG.application.account.api.endpoints.captcha.method,
        headers: CONFIG.application.account.api.headers,
      
      }
    });
   
    captchaTokenContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { captchaTokenContext, captchaTokenMethod }
}

export default index;
