import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Account Pin Update";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': setup.setup.thor.moduleCode,
          'Authorization': null,
        },
        endpoints: {
          updatePin: {
           
            url: setup.setup.api.countdown_v1.url[environment] + '/pin/',
           
            method: 'PUT'
          },
          updatePassword: {
           
            url: setup.setup.api.countdown_v1.url[environment] + '/members/changePassPin',
           
            method: 'POST'
          }
        }
      },
      objects: {
        pin: { name: "pin", initial: "", label: "New PIN", helperText: false, rules: ["isMinMax","isPinChecklist"], allow_null: true, minmax_limit: 4, minmax_suffix: "digit pin" },
        pin_confirm: { name: "pin_confirm", initial: "", label: "Confirm new PIN", helperText: false, rules: ["isConfirm","isMinMax"], allow_null: true, minmax_limit: 4, minmax_suffix: "digit", confirm_to: "pin", confirm_suffix: "pin match" },
 },
      responses: {
        "fail": "Not able to update your pin"
      }
    }
  },
  routes: {
    label: "Pin Change",
    prefix: "/u/pin-change/:token"
  },
  setup
}

export default config;
