const isRequiredOneCard = (data) =>{
 
  const value = data.value.trim();
  const config = data.config;
  const limit = config.limit;
  const minLimit = config.minLimit;
  const suffix = config.minmax_suffix;
  const nullAllow = config.allow_null ? true : false;
 
  if (nullAllow && value.length < 1) return true;
  if (value.length === limit && isNaN(value)==false){
   
    return true
  }
  if (value.length === minLimit && isNaN(value)==false){
   
    return true
  } if (value.length > minLimit && value.length < limit && isNaN(value)==false ){
   
    return true
  }
  
  else{return  "Please enter a valid onecard number " }
  
}

export default isRequiredOneCard;
