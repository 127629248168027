import React, { useEffect, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook } from '../../lib-atomic-functions';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* sections */
import Loader from '../loader';
/* sections */

/* functions  */
import { PinReset } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';

const account = observer(({account, props}) => {

  /* state */
  const { accountPinResetContext, accountPinResetMethod } = PinReset({CONFIG});
  /* state */

  const { emailAddress } = account.traderDetails;

  // const { emailAddress } = account;

  const handleCancel = () => {
    props.history.push('/account/details');
  }

  const handlePinReset = async () => {
    // LocalStorage.addLS('detail', { emailAddress });
    const body = JSON.stringify({ emailAddress });
    const pinReset = await accountPinResetMethod({body});
    if (accountPinResetContext.data.invalidToken) return false;
    props.history.push('/u/landing-pin-reset-logged-in');
  }

  const submitButton = { fullWidth: true, onClick: handlePinReset }
  const cancelButton = { fullWidth: true, onClick: handleCancel }
  const failReset = { error: ["Sorry, there was a problem resetting your PIN, please call us on 0800 40 40 40 for one of our team to assist."] }

  return <Fragment>
    { accountPinResetContext.data.invalidToken &&
      <Fragment>
      <Invalid {...failReset} />
      <br/>
      </Fragment>
    }
    <Typography variant="subtitle2" align="center" gutterBottom>
    For security purposes, an email will be sent to <b>{ emailAddress }</b> for you to change your password. To proceed, please click submit below.
    </Typography>
    
    {/* <Typography variant="subtitle2" align="center" noWrap>
      <b>{ emailAddress }</b>
    </Typography> */}
    
    <div>&nbsp;</div>
    <Buttons.Normal {...submitButton}>Submit</Buttons.Normal>
    <div>&nbsp;</div>
    <Buttons.Text {...cancelButton}>Cancel</Buttons.Text>
  </Fragment>

});

export default account;
