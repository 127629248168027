import React from 'react'

/* packages */
import Packages from './packages';
const {  Modules } = Packages;
/* packages */

const Links = props => {
  return Modules.map((Module, i) => {
    if (!Module.Links) return null;
    return <Module.Links {...props} key={i} />
  });
}

export default Links;
