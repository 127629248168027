import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import StickyFooter from 'react-sticky-footer';
import { textAlign } from '@material-ui/system';
const useStyles = makeStyles(theme => ({
  root: {
    color: "red"
  },
  appBar: {
   position:'unset !important',
   textAlign:'center',
   paddingBottom:'20px'
  },
  grow: {
    flexGrow: 1,
  },
  footerLink: {
    color: "black",
    textDecoration: "none",
    fontSize: "large",
  },

  footerTXLogo: {
    width: "80%",
    maxWidth: "100px",
    height: "auto",
    verticalAlign: "baseline"
  }
}));


export default function BottomAppBar() {
  const classes = useStyles();
  const date = new Date();
  const Year = date.getFullYear();
  return <div variant="caption" align="center" className={classes.appBar}>
  <a href="https://www.woolworths.co.nz/info/more-ways-to-save/christmas-club" target="_blank" className={classes.footerLink}><small>FAQs</small></a>
    &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
  <a href="https://www.woolworths.co.nz/info/terms-and-conditions" target="_blank" className={classes.footerLink}><small>Terms and conditions</small></a>
    &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
  <a href="https://www.woolworths.co.nz/info/privacy" target="_blank" className={classes.footerLink}><small>Privacy policy</small></a>
</div>
  

}
