/* development */
import Balance from '../../app-module-account-balance';
import Transaction from '../../app-module-account-transaction';
import Profile from '../../app-module-account-profile';
import ProfileEdit from '../../app-module-account-profile-edit';
import ProfilePinReset from '../../app-module-account-profile-pin-reset';
import TopupGuide from '../../app-module-account-topup-guide';

const Modules = [
  Balance,
  Transaction,
  Profile,
  ProfileEdit,
  ProfilePinReset,
  TopupGuide,
]

export default {
  Modules
}
