import React from 'react'

/* packages */
import Packages from './packages';
const {  Modules } = Packages;
/* packages */

const Routes = props => {
  return Modules.map((Module, i) => {
    return <Module.Routes key={i} />
  });
}

export default Routes;
