import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles';
/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { Buttons } from '../../lib-atomic';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

import CONFIG from '../config';
const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
}));
/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */

const index = observer((props) => {

  LocalStorage.removeLS(); // remove all local storages
  const linkButton = { size: "small" }
  const classes = useStyles();
  return <Fragment>
    <Typography style={{height: "87px", marginTop: "19px"}}  align="center" gutterBottom>
      <img  className={classes.paper} src={LOGO} />
    </Typography>
    <ModulePaperWrapper>
      {/* <img src={LANDINGGRAPHICS} width="100%" /> */}
      <br/>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" gutterBottom>
      <b> This card has been placed on hold.</b>
      </Typography>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2">
        If you believe this is incorrect or need further assistance,<b> please call us on 0800 40 40 40</b>.
      </Typography>
      <br/>
      <Buttons.Primary {...linkButton} >
        <Link to="/u/login">Back</Link>
      </Buttons.Primary>
    </ModulePaperWrapper>
  </Fragment>

})

export default index;
