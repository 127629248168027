import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { withTheme } from '@material-ui/styles';
import StickyFooter from 'react-sticky-footer';
const useStyles = makeStyles(theme => ({
  root: {
    color: "red"
  },
  appBar: {
    boxShadow: "none",
    backgroundColor:"#fff",
    
  
    color: "#fff",
  },
  grow: {
    flexGrow: 1,
  },
  footerLink: {
    color: "black",
    textDecoration: "none",
  },
  footerText: {
    marginBottom: 5,
    color: "black",
    textAlign:'center'
  },
  footerTXLogo: {
    width: "80%",
    maxWidth: "100px",
    height: "auto",
    verticalAlign: "baseline"
  }
}));


export default function BottomAppBar() {
  const classes = useStyles();
  const date = new Date();
  const Year = date.getFullYear();
  return <div
  
  style={{
  backgroundColor: "white",
  padding: "2rem",
    textAlign:"center"

  }}

>

    <br/>
   
    <Typography variant="caption" align="center" className={classes.footerText}>
      <small>
      POWERED BY
      &nbsp;&nbsp;&nbsp;
      <a href="http://www.tranxactor.com" target="_blank">
      <img src="https://mj-woolworths-uat.tr4ns.com/assets/countdown/tx_logo.png" className={classes.footerTXLogo} />
      </a>
      &nbsp;&nbsp;&nbsp;
      © {Year}. ALL RIGHTS RESERVED
      </small>
    </Typography>
  
     
     
   
  
  
</div>
 

}
