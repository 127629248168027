import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "login";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': setup.setup.thor.moduleCode,
          'Authorization': null
        },
        endpoints: {
          register: {
            doc: 'https://countdown-uat.txweb.site/members/registration',
            url: setup.setup.api.countdown_v1.url[environment] + '/members/registration',
            method: 'POST'
          },
          auth: {
            doc: 'https://countdown-uat.txweb.site/auth/token',
            url: setup.setup.api.countdown_v1.url[environment] + '/login/',
            method: 'POST'
          },
          detail: {
            doc: 'https://countdown-uat.txweb.site/member/enquiry',
            url: setup.setup.api.countdown_v1.url[environment] + '/members/enquiry',
            method: 'GET'
          },
          captcha: {
            doc: 'https://countdown-uat.tr4ns.com/recaptcha',
            url: setup.setup.api.countdown_v1.url.captcha + '/recaptcha?token=',
            method: 'GET'
          }
        }
      },
      responses: {
        "fail": "Incorrect card number or PIN"
      }
    },
    stepOne: {
      api: {
        headers: {
          'Content-Type': 'application/json'
        }
      },
      objects: {
        username: { name: "username", initial: "", label: "Card number", label2:"card number", helperText: false, rules: ["isRequired"], allow_null: false, minmax_limit: 16, minmax_suffix: "" },
        password: { name: "password", initial: "", label: "PIN", label2:"PIN", helperText: false, rules: ["isRequired"], allow_null: false, minmax_limit: 4, minmax_limit: 4,  minmax_suffix: "" },
      },
      responses: {
        "fail": "Incorrect card number or PIN"
      }
    },
    stepTwo: {
      api: {
        headers: {
          'Content-Type': 'application/json'
        }
      },
      objects: {
        pin: { name: "pin", initial: "", label: "New PIN", helperText: false, rules: ["isMinMax", "isNumber","isPinChecklist"], allow_null: false, minmax_limit: 4, minmax_suffix: "digit pin", validationName:"4 digit pin" },
        pin_confirm: { name: "pin_confirm", initial: "", label: "Confirm New PIN", helperText: false, rules: ["isConfirm","isMinMax", "isNumber"], allow_null: false, minmax_limit: 4, minmax_suffix: "digit", confirm_to: "pin", confirm_suffix: "pin match", validationName:"4 digit pin" },
        titleId: { name: "titleId", initial: "", label: "Title", helperText: false, rules: false, validationName:"title"},
        firstName: { name: "firstName", initial: "", label: "First name*", helperText: false, rules: ["isNotNull" ] , validationName:"first name"},
        lastName: { name: "lastName", initial: "", label: "Last name*", helperText: false, rules: ["isNotNull" ] , validationName:"last name"},
        dob: { name: "dob", initial: "DD/MM/YYYY", label: "Date of birth*", helperText: false, rules: ["isNotNull"] , validationName:"date of birth", allow_null: false},
        genderId: { name: "genderId", initial: "", label: "Gender*", helperText: false, rules: ["isNotNull"],  validationName:"gender" },
        emailAddress: { name: "emailAddress",maxLength: 10, initial: "", label: "Email*", helperText: false, rules: ["isEmail"] , validationName:"email address"},
        mobileNumber: { name: "mobileNumber", initial: "", label: "", helperText: false, rules: ["isMinMaxPhoneNumber"], allow_null: true, minmax_suffix: "digit number", limit:10, minLimit:6, validationName:"phone number"},
        phone2: { name: "phone2", initial: "", label: "", helperText: false, rules: false, rules: ["isMinMaxPhoneNumber"], allow_null: true, minmax_limit: 7, minmax_suffix: "digit number",  limit:7, minLimit:7, validationName:"title", validationName:"phone number" },
        prefixMobileNumber: { name: "prefixMobileNumber", initial: "", label: " ", helperText: false, rules: false, max:"10", validationName:"title" },
        prefixPhone2: { name: "prefixPhone2", initial: "", label:  " ", helperText: false, rules: false },
        address1: { disabled: true, name: "address1", initial: "", label: "Address 1", helperText: false, rules: false, validationName:"title" },
        address2: { disabled: true, name: "address2", initial: "", label: "Address 2", helperText: false, rules: false , validationName:"title"},
        suburb: { disabled: true, name: "suburb", initial: "", label: "Suburb", helperText: false, rules: false , validationName:"title"},
        city: { disabled: true, name: "city", initial: "", label: "City", helperText: false, rules: false , validationName:"title"},
        postcode: { disabled: true, name: "postcode", initial: "", label: "Postal Code", helperText: false, rules: false },
        physicalAddress1: { disabled: true, name: "physicalAddress1", initial: "", label: "Address 1*", helperText: false, rules: false, validationName:"address1", },
        physicalAddress2: { disabled: true, name: "physicalAddress2", initial: "", label: "Address 2", helperText: false, rules: false, validationName:"address2" },
        physicalSuburb: { disabled: true, name: "physicalSuburb", initial: "", label: "Suburb*", helperText: false, rules: false, validationName:"suburb", },
        physicalCity: { disabled: true, name: "physicalCity", initial: "", label: "City*", helperText: false, rules: false, validationName:"city", },
        physicalPostCode: { disabled: true, name: "physicalPostCode", initial: "", label: "Postcode*", helperText: false, rules: false, validationName:"postcode", },
        // oneCard: { name: "oneCard", initial: "", label: "Onecard number*", helperText: false, rules: ["isRequiredOneCard"], minmax_limit: 8, minmax_suffix: "digit number", validationName:"onecard number", limit:13, minLimit:12},
        password: { name: "password", initial: "", label: "Create Password", helperText: false, rules: ["passwordCheck"], allow_null: false, minmax_limit: 8,  validationName:"password"},
        password_confirm: { name: "password_confirm", initial: "", label: "Confirm Password", helperText: false, rules: ["isConfirm"], allow_null: false, confirm_to: "password", confirm_suffix: "password match", },
        flOptin: { name: "flOptin", initial: true, label: "", helperText: false, rules: false },
      },
      responses: {
        "fail": "Unable to register your details"
      }
    }
  },
  routes: {
    label: "Registration",
    prefix: "/u/registration"
  },
  setup,
  environment
}

export default config;
