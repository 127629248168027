const addLS = (key, value) => {
  try {
    const ls = sessionStorage.getItem('ls');
    const obj = ls ? JSON.parse(ls) : {}
    obj[key] = value;
    const newLs = JSON.stringify(obj);
    sessionStorage.setItem('ls', newLs);
    return true;
  } catch (e) { console.log(e); return false }
}

const getLS = () => {
  try {
    const ls = sessionStorage.getItem('ls');
    const obj = ls ? JSON.parse(ls) : false
    return obj;
  } catch (e) { return false }
}

const removeLS = () => {
  try {
    return sessionStorage.removeItem('ls');
  } catch (e) { return false }
}

export default {
  addLS,
  getLS,
  removeLS
}

// import SecureLS from "secure-ls";

// const addLS = (key, value) => {
//   try {
//     const securels = new SecureLS();
//     // const ls = localStorage.getItem('ls');
//     const ls = securels.get('ls');
//     const obj = ls ? JSON.parse(ls) : {}
//     obj[key] = value;
//     const newLs = JSON.stringify(obj);
//     securels.set('ls', newLs);
//     // localStorage.setItem('ls', newLs);
//     return true;
//   } catch (e) { console.log(e); return false }
// }

// const getLS = () => {
//   try {
//     const securels = new SecureLS();
//     // const ls = localStorage.getItem('ls');
//     const ls = securels.get('ls');
//     const obj = ls ? JSON.parse(ls) : false
//     return obj;
//   } catch (e) { return false }
// }

// const removeLS = () => {
//   try {
//     const securels = new SecureLS();
//     return securels.remove('ls');
//     // return localStorage.removeItem('ls');
//   } catch (e) { return false }
// }

// export default {
//   addLS,
//   getLS,
//   removeLS
// }
