import isNotNull from "./isNotNull";
import isEmail from "./isEmail";
import isPassword from "./isPassword";
import isNumber from "./isNumber";
import isLetter from "./isLetter";
import isMin from "./isMin";
import isMinMax from "./isMinMax";
import isMinMaxPhoneNumber from "./isMinMaxPhoneNumber";
import isDate from "./isDate";
import isJson from "./isJson";
import isConfirm from "./isConfirm";
import isRequired from "./isRequired";
import isRequiredOneCard from "./isRequiredOneCard";
import isPinChecklist from "./isPinChecklist";
import passwordCheck from "./passwordCheck"

export default {
  isNotNull,
  isEmail,
  isPassword,
  isNumber,
  isLetter,
  isMin,
  isMinMax,
  isMinMaxPhoneNumber,
  isDate,
  isJson,
  isConfirm,
  isRequired,
  isRequiredOneCard,
  isPinChecklist,
  passwordCheck
}
