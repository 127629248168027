import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Landing Registration Resend Activation";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },

  routes: {
    label: "How to top up your card",
    prefix: "/u/landing-topup"
  },
  setup,
  environment
}

export default config;
