import React from 'react'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

const ttile = props => {
  return <Typography style={{fontFamily: 'Fresh sans,Fresh,Arial,Helvetica,sans-serif'}} style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" align="center" gutterBottom>
    <b>Reset your Password</b>
  </Typography>
}

export default ttile;
