import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Account Transaction";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': setup.setup.thor.moduleCode,
          'Authorization': null,
        },
        endpoints: {
          transaction: {
            
            url: setup.setup.api.countdown_v1.url[environment] + '/transactions/history/',
            
            method: 'GET'
          }
        }
      },
      objects:{
        startdob: {
          name: "startdob",
          initial: "",
          placeholder: "Start Date",
          helperText: false,
          rules: false,
          label: "Start date"
        },
        enddob: {
          name: "enddob",
          initial: "",
          placeholder: "End Date",
          helperText: false,
          rules: false,
         label: "End date"
        },
      },
    }
  },
 
  routes: {
    label: "Transaction",
    prefix: "/account/transaction"
  },
  setup
}

export default config;
