import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Customer List";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    demo: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ''
        },
        endpoints: {
          demo: {
            doc: setup.setup.url + '/data/customer_list.json',
            url: setup.setup.url + '/data/customer_list.json',
            method: 'GET'
          }
        }
      }
    }
  },
  routes: {
    label: "List",
    prefix: "/customer/list"
  },
  setup
}

export default config;
