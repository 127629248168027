import React from 'react';
import { Route, Switch } from "react-router-dom";

import ThqLandingXs from './layout/thq-landing-xs';
import ThqLandingSm from './layout/thq-landing-sm';
import ThqLandingLg from './layout/thq-landing-lg';
import ThqMain from './layout/thq-main';
import NewLanding from './layout/thq-landing-logout';
import TopUP from './layout/thq-topup-landing-sm';
// google Analytics

/* CONSTANTS */





const App = props => {
 
  return <Switch>
     
    <Route path="/u/pin-reset" component={NewLanding} />
    <Route path="/u/pin-update" component={NewLanding} />
    <Route path="/u/pin-change" component={NewLanding} />
    <Route path="/u/login" component={NewLanding} />
    <Route path="/u/landing-topup" component={TopUP} />
    <Route path="/u/registration" component={NewLanding} />
    <Route path="/u/logout" component={NewLanding} />
    <Route path="/u/landing-login-logout" component={NewLanding} />
    <Route path="/u/landing-token-expire" component={NewLanding} />
    <Route path="/u/landing-registration-exist" component={NewLanding} />
    <Route path="/u/landing-non-registered" component={NewLanding} />
    <Route path="/u/landing-registration-pending" component={NewLanding} />
    <Route path="/u/landing-registration-on-hold" component={NewLanding} />
    <Route path="/u/landing-registration-terminated" component={NewLanding} />
    <Route path="/u/landing-pin-reset-logged-in" component={NewLanding} />
    <Route path="/u/landing-pin-update" component={NewLanding} />   
    <Route path="/u/landing-pin-change" component={NewLanding} />   
    <Route path="/u/landing-registration-send-activation" component={NewLanding} />
    <Route path="/u/landing-registration-activation/:token" component={NewLanding} />
    <Route path="/u/landing-idle-user" component={NewLanding} />  
    <Route path="/:module" component={ThqMain} />
  </Switch>
}

export default App;
