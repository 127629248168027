import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'
import Moment from 'react-moment';

/* mui */
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { Buttons } from '../../lib-atomic';
import { ModulePaperInfoWrapper } from '../../lib-atomic-design-section';
import { ModulePaperProfileWrapper } from '../../lib-atomic-design-section';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */
// google Analytics
import ReactGA from 'react-ga';
/* sections */
import Loader from '../loader';
/* sections */

/* functions  */
import { AccountDetail } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';

/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LOGOSMALL = CONFIG.setup.assets.logo_small;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */
function initializeReactGA(props1) {
  
  
  ReactGA.set({page:window.location.pathname})

  ReactGA.initialize('UA-151657300-1');
  ReactGA.pageview(window.location.pathname);

}
const index = observer((props) => {
  initializeReactGA();
  
  const LS = LocalStorage.getLS();
  const { login } = LS;
  if (!login) return <Redirect to='/u/logout' />

  const {token} = LS;
  /* state */
  const { accountDetailContext, accountDetailMethod } = AccountDetail({CONFIG});

  useEffect(() => {
    accountDetailMethod(token);
  }, []);

  useEffect(() => {
    if (accountDetailContext.expireToken) props.history.push('/u/landing-token-expire');
  }, [accountDetailContext.expireToken]);
  /* state */

  if (accountDetailContext.loading) return <Loader />
  if (!accountDetailContext.data.traderDetails) return <Loader />
  if (accountDetailContext.expireToken) return <Loader /> // redirect
  initializeReactGA(accountDetailContext.data.traderDetails);
  /* data props */
  const submitButton = { size: "small" }
  /* data props */


 
const {  firstName, lastName, genderId, birthDate, emailAddress, mobileNumber, phone2,city, address1, physicalAddress1, suburb,  postcode, titleId, externalRef, physicalAddress2,physicalPostcode,physicalSuburb,prefixMobileNumber,prefixPhone2,physicalCity } = accountDetailContext.data.traderDetails;



const {cardNumber} = accountDetailContext.data;
  //for prod env
  // const titleList = {
  //   "289": "Dr",
  //   "288": "Mx",
  //   "287": "Miss",
  //   "286": "Ms",
  //   "285": "Mrs",
  //   "284": "Mr"
  // }

  //for dev env
  const titleList = {
    "246": "Dr",
    "245": "Mx",
    "244": "Miss",
    "243": "Ms",
    "242": "Mrs",
    "241": "Mr"
  }
  
  const genderList = {
    "1": "M",
    "2": "F",
    "3": "X",
  
  }
  const myTitle = titleList[titleId];
  const myGender = genderList[genderId];

  // if (externalRef == null||externalRef == undefined){
  //   var carNumber = "None"
  // }else{
  //   carNumber = externalRef;
  // }


  if(mobileNumber == null||mobileNumber == undefined || mobileNumber=="  "){
   
    var mobileNumber1 =  <div></div>
  }else{
    mobileNumber1 = <div>  <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  color:"black", fontSize: "medium"}} variant="caption" noWrap>
    Mobile 
   </Typography>
   <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
     {prefixMobileNumber}{ mobileNumber }
   </Typography> <br/></div>
  }


  if(phone2 == null||phone2 == undefined || phone2=="  "){
   
    var phone =  <div></div>
  }else{
    phone = <div><Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',color:"black",  fontSize: "medium"}} variant="caption" noWrap>
    Home 
   </Typography>
   <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
    {prefixPhone2}{ phone2 }
   </Typography> <br/></div>
  }

  if(address1 == null||address1 == undefined||address1 == " "){
   
    var addr =  <div></div>
  }else{
    addr = <div> <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"black",  fontSize: "medium"}} variant="caption" noWrap>
    Postal address
   </Typography>
   <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
   { address1 }&nbsp;{suburb}&nbsp;{city}&nbsp;{postcode}
   </Typography> <br/></div>
  }


  return <ModulePaperProfileWrapper >
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"black", fontSize: "medium"}} variant="caption" noWrap>
     Card number
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
      {cardNumber}
    </Typography>
    <br/>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"black",  fontSize: "medium"}} variant="caption" noWrap>
     Title
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
      { myTitle }
    </Typography>
    <br/>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"black", fontSize: "medium"}} variant="caption" noWrap>
     First name
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
      { firstName }
    </Typography>
    <br/>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"black", fontSize: "medium"}} variant="caption" noWrap>
     Last name
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
      { lastName }
    </Typography>
    <br/>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"black", fontSize: "medium"}} variant="caption" noWrap>
     Gender
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
      { myGender  }
    </Typography>
    <br/>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',color:"black",   fontSize: "medium"}} variant="caption" noWrap>
     Date of birth
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
      <Moment format="DD/MM/YYYY">{ birthDate }</Moment>
    </Typography>
    <br/>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',color:"black",  fontSize: "medium"}} variant="caption" noWrap>
     Email
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
      { emailAddress }
    </Typography>
    <br/>
  {mobileNumber1}
    
   {phone}
   
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',color:"black",  fontSize: "medium"}} variant="caption" noWrap>
    Physical address
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
    {physicalAddress1}&nbsp;{physicalSuburb}&nbsp;{physicalCity}&nbsp;{physicalPostcode}
    </Typography>
    <br/>
   {addr}
   
    {/* <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"black", fontSize: "medium"}} variant="caption" noWrap>
     Onecard number
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',  fontSize: "medium", fontWeight: "bold"}} variant="subtitle2" noWrap>
      {carNumber}
    </Typography> */}

  </ModulePaperProfileWrapper>

})

export default index;
