import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Landing Registration Pending";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    demo: {
      api: {
        headers: {
          'Content-Type': 'application/json'
        },
        endpoints: {
          demo_endpoint: {
            url: "",
            method: 'POST/GET/PUT'
          }
        }
      },
      objects: {
        demo: { name: "demo", initial: "", label: "Demo", helperText: false, rules: ["isNotNull"] },
      },
      responses: {
        "sucess": "this is a demo response"
      }
    }
  },
  routes: {
    label: "Registration Pending",
    prefix: "/u/landing-non-registered"
  },
  setup
}

export default config;
