import React from 'react';

import Button from '@material-ui/core/Button';

/*
: properties
children
size
fullWidth
*/

const normal = props => {
  return <Button   {...props} variant="contained" color="primary" type="submit" style={{ fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',paddingLeft: "32px", paddingRight: "32px", borderRadius: "4px", height: "44px", textTransform: "none", marginRight:"0px"}} >
    { props.children }
  </Button>
}

export default normal;
