import demo from '../../app-module-demo-container'
import account from '../../app-module-account-container'

const Modules = {
  demo,
  account
}

export default {
  Modules
}
