import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import PinResetStore from './store';
/* stores */

const index = ({CONFIG}) => {
  const accountPinResetContext  = useContext(PinResetStore);
  const accountPinResetMethod = async ({body,token}) => {
    accountPinResetContext.setLoading({loading: true, status: false, data: false});
    CONFIG.application.account.api.headers.token = token;
    const fetch = await Fetch({
      url: CONFIG.application.account.api.endpoints.resetPin.url,
      config: {
        method: CONFIG.application.account.api.endpoints.resetPin.method,
        headers: CONFIG.application.account.api.headers,
        body
      }
    });
   
    accountPinResetContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { accountPinResetContext, accountPinResetMethod }
}

export default index;
