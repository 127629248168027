import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Account Profile Detail";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': setup.setup.thor.moduleCode,
          'Authorization': null,
        },
        endpoints: {
          detail: {
            doc: 'https://countdown-uat.txweb.site/member/enquiry',
            url: setup.setup.api.countdown_v1.url[environment] + '/members/enquiry',
            method: 'GET'
          }
        }
      }
    }
  },
  routes: {
    label: "Profile Detail",
    prefix: "/account/profile/detail"
  },
  setup
}

export default config;
