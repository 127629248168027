import React from 'react';

import Modules from '../../modules/main-modules';

const BodyImports = props => {
  const { module } = props.match.params;
  for (const mod in Modules) {
    if (mod === module) {
      const { Routes } = Modules[mod];
      return <Routes {...props} />
    }
  }
}

export default BodyImports;
