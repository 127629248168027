import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { Buttons } from '../../lib-atomic';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* functions  */
import { EmailAttr, EmailResend } from '../../app-module-account-container/functions'
/* functions  */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CONFIG from '../config';
 const useStyles = makeStyles(theme => ({
    paper: {
      width: "100%",
      height: "auto"
    },
  }));
/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */

const index = observer((props) => {

  const { login , emailAddress } = LocalStorage.getLS();
  if (!login) props.history.push("logout");
  const { token, userId,  } = login;

  /* state */
  const { accountEmailAttrContext, accountEmailAttrMethod } = EmailAttr({CONFIG});
  const { accountEmailResendContext, accountEmailResendMethod } = EmailResend({CONFIG});
  // useEffect(() => {
  //   accountEmailAttrMethod({token, traderId: userId});
  // }, []);
  // if (!accountEmailAttrContext.data) return <div>loading....</div>
  /* state */
 
  const handleResend = async () => {
    

    await accountEmailResendMethod({token});
    if (!accountEmailResendContext.data) return false;
    props.history.push('/u/landing-registration-send-activation');
  }

  const yesButton = { size: "small", onClick: handleResend }
  const linkButton = { size: "small" }
  const classes = useStyles();
  return <Fragment>
    <Typography style={{height: "87px", marginTop: "19px"}} align="center" >
      <img  className={classes.paper} src={LOGO} />
    </Typography>
    <ModulePaperWrapper>
      {/* <img src={LANDINGGRAPHICS} width="100%" /> */}
      <br/>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" gutterBottom>
      <b>This account is still pending activation.</b>
      </Typography>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2">
       To activate your account, we need you to verify your email address. A<b> verification link </b>has been sent to <b> {emailAddress}</b><br/><br/>Kindly check your email <b>inbox</b> or <b>spam</b> folder and click on the link to activate your account.
      </Typography>
      <br/>
        <Buttons.Normal style={{marginRight: '5px'}} {...yesButton} >Resend email</Buttons.Normal>
      {/*
        <Buttons.Primary {...linkButton} >
          <Link to="/u/landing-registration-send-activation">Yes</Link>
        </Buttons.Primary>
        */}
      {/* <Buttons.Normal {...linkButton} >
        <Link to="/u/logout">Back</Link>
      </Buttons.Normal> */}
    </ModulePaperWrapper>
  </Fragment>

})

export default index;
