import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListIcon from '@material-ui/icons/List';
/* mui */

/* packages */
import { ModulePaperWrapper,
  ModulePaperCreditBalanceWrapper,
  ModulePaperBonusCurrentWrapper,
  ModulePaperBonusPreviousWrapper,
  ModulePaperBonusPreviousWrapperSub,
  ModulePaperDateWrapper,
  ModulePaperInfoWrapper
 } from '../../lib-atomic-design-section';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* sections */
import Loader from '../loader';
/* sections */

/* functions  */
import { AccountBalance } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  item: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },

  carryOver: {
    width: "432px !important",
    height: "110px !important"
  },
  



}));
/* CONSTANTS */

const index = observer((props) => {

  const classes = useStyles();

  const LS = LocalStorage.getLS();
  const { login } = LS;
  if (!login) return <Redirect to='/u/logout' />

  const { token } = LS;

  /* state */
  const { accountBalanceContext, accountBalanceMethod } = AccountBalance({CONFIG});

  

  var month = new Date(). getMonth() + 1;
  var text = "Includes bonus available to spend in December and January."
  if(month != 12||month != 1){
   
    text = ""
  }

  
 
  useEffect(() => {
    accountBalanceMethod(login);
  }, []);

  useEffect(() => {
    if (accountBalanceContext.expireToken) props.history.push('/u/landing-token-expire');
  }, [accountBalanceContext.expireToken]);
  /* state */

  if (accountBalanceContext.loading) return <Loader />
  if (!accountBalanceContext.data) return <Loader />
  if (accountBalanceContext.expireToken) return <Loader /> // redirect

  /* data props */
  const { bonusForecast, bonusLocked, creditBalance, firstTopup, totalTopUp } = accountBalanceContext.data;
  
  const isBunosShow = bonusLocked == 0.00 ? false : true;
  
  /* data props */
  const text3 = text;
 
  return <Fragment>

    {/* { firstTopup &&
    <ModulePaperDateWrapper >
      <Typography variant="caption">
         <i><b><Moment format="DD/MM/YYYY">{ firstTopup }</Moment></b> is your first topup date.</i>
      </Typography>
    </ModulePaperDateWrapper>
    } */}

    <Grid container spacing={1}>

    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
    <ModulePaperCreditBalanceWrapper >
      <Typography  variant="body2" align="right" style={{position: "relative"}}>
        <Link to="/account/topup-guide" variant="body2" style={{position: "absolute", right: 0, color: "black",}}>
         <a style={{ fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontSize:'large'}}>How to top up your card</a>
        </Link>
      </Typography>
      <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif' , fontSize: "larger"}} variant="subtitle2">
        <b>Balance</b>
      </Typography>
      <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"#007837 "}} variant="h3">
        {/* <b>${ creditBalance }</b> */}
        <NumberFormat value={creditBalance} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      </Typography>
      <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', display:'text'}} variant="caption" gutterBottom>
        {text3}
      </Typography>
    </ModulePaperCreditBalanceWrapper>
    </Grid>

    { isBunosShow &&
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
    <ModulePaperBonusPreviousWrapper>
      <Grid container wrap="nowrap" spacing={2} >
        <Grid item xs zeroMinWidth  >
          <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontSize: "larger"}} variant="subtitle2">
            <b>Carryover Bonus</b>
          </Typography>
          <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} variant="caption" gutterBottom>
            Will be added back to your balance next December.
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"#007837 " , fontWeight: 'bold'}} variant="h5">
            {/* <b>${ bonusLocked }</b> */}
            <NumberFormat value={bonusLocked} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </Typography>
        </Grid>
      </Grid>
    </ModulePaperBonusPreviousWrapper>
    </Grid>
    }


    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
    <ModulePaperBonusPreviousWrapperSub>
      <Grid container>
        <Grid item xs={6}>
          <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontSize: "larger"}} variant="body2" align="left">
            <b>
            Top ups
            this period
            </b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"#007837 ", fontWeight: 'bold'}} variant="h5" align="right">
            {/* <b>${ totalTopUp }</b> */}
            <NumberFormat value={totalTopUp} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </Typography>
        </Grid>
      </Grid>
    </ModulePaperBonusPreviousWrapperSub>
    </Grid>

    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
    <ModulePaperBonusPreviousWrapperSub>
      <Grid container>
        <Grid item xs={6}>
          <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontSize: "larger"}} variant="body2" align="left">
            <b>
            Bonus
            forecast
            </b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', color:"#007837 " , fontWeight: 'bold'}} variant="h5" align="right">
            {/* <b>${ bonusForecast }</b> */}
            <NumberFormat value={bonusForecast} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </Typography>
        </Grid>
      </Grid>
    </ModulePaperBonusPreviousWrapperSub>
    </Grid>

    </Grid>


{/*
    <div class="l-wrap">
  <div class="l-guest-article">
    <div class="grid-item l-guest"> <div class="grid-inner">
     */}
    {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6} > */}



{/* </Grid>  */}
 {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6}> */}

{/* </Grid>  */}

    {/* <Grid container wrap="nowrap" spacing={1} >
      {/* left */}


      {/* right */}

    {/* </Grid> */}

    {/*
      <ModulePaperBonusCurrentWrapper>
        <Typography variant="subtitle2">
          <b>NZD</b>
        </Typography>
        <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4">
          <b>${ bonusForecast }</b>
        </Typography>
        <Typography variant="caption">
          BONUS FORECAST BASED ON CURRENT BALANCE
        </Typography>
      </ModulePaperBonusCurrentWrapper>




      <ModulePaperBonusPreviousWrapper>
        <Typography variant="subtitle2">
          <b>NZD</b>
        </Typography>
        <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4">
          <b>${ bonusLocked }</b>
        </Typography>
        <Typography variant="caption">
          CARRY OVER BONUS AVAILABLE BETWEEN <b>1 DECEMBER</b> AND <b>31 JANUARY</b>
        </Typography>
      </ModulePaperBonusPreviousWrapper>
      */}

  </Fragment>

})

export default index;
