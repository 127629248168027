import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'
import Typography from '@material-ui/core/Typography';
/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook,LocalStorage } from '../../lib-atomic-functions';
/* packages */
/* functions  */
import { PinUpdate } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';

/* CONSTANTS */
const RESETOBJECTS = CONFIG.application.account.objects;
/* CONSTANTS */

const reset = observer((props) => {

  const { token } = props.match.params;

  /* state */
  const { accountPinUpdateContext, accountPinUpdateMethod } = PinUpdate({CONFIG});
  /* state */

 
  let cardNumber;
  if(LocalStorage.getLS('cardNumber')) cardNumber = LocalStorage.getLS('cardNumber');


  const handlePinUpdate = async () => {
    let request;
    if(cardNumber){
     request = {
      "cardNumber":cardNumber.cardNumber,
      "password":values.password
     }
    }else{
      request = {
        "password":values.password
       }
    }
   
     await accountPinUpdateMethod({token, body:request});
    if (accountPinUpdateContext.data.invalidToken) return false;
   props.history.push('/u/landing-pin-update');
  }

  const handleReset = () => {
    const { history } = props;
  }

  const {
    values,
    onChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handlePinUpdate, RESETOBJECTS);

  const password = { ...RESETOBJECTS.password, error: error.password, value: values.password, onChange };
  const password_confirm = { ...RESETOBJECTS.password_confirm, error: error.password_confirm, value: values.password_confirm, onChange };
  const submitButton = { fullWidth: true }
  const failUpdate = { error: ["Unable to update your pin. Invalid reset token."] }

  return <form onSubmit={onSubmit} noValidate>
    { accountPinUpdateContext.data.invalidToken &&
      <Fragment>
      <Invalid {...failUpdate} />
      </Fragment>
    }
       <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
       Please create a secure password to log in to the Christmas Club website in the future. Your password must be at least 8 to 25 characters long and adhere to the following
      </Typography>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      •	Please include at least one uppercase letter (A to Z)
        </Typography>
        <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
        •	Please include as least one lowercase letter (a to z)
        </Typography>
        <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
        •	Please include at least one digit (0 to 9)
        </Typography>
        <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
        •	Please include at least one special character
        </Typography>
    <Inputs.Password {...password} inputProps={{maxLength: 25,}}/><br/> <br/>
    <Inputs.Password {...password_confirm} inputProps={{maxLength: 25,}}/>
    <div>&nbsp;</div>
    <Buttons.Normal {...submitButton}>Submit</Buttons.Normal>
  </form>

})

export default reset;
