import React from 'react';

import Button from '@material-ui/core/Button';

/*
: properties
children
onClick
size
fullWidth
*/

const primary = props => {
  return <Button {...props} variant="contained" color="primary" style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',paddingLeft: "32px", paddingRight: "32px", borderRadius: "4px", height: "44px" , textTransform: "none"}} >
    { props.children }
  </Button>
}

export default primary;
