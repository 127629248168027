import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* packages */
import { Inputs, Buttons } from '../../lib-atomic';
/* packages */

import CONFIG from '../config';

// key for prod env:- 9H6QCRML43EPJDWXFNTV
// key for dev env :- MY9BDUECNWHRT7FPQLG8
/* CONSTANTS */
// const ACCOUNTOBJECTS = CONFIG.application.stepTwo.objects;
/* CONSTANTS */

const account = observer(({handle, props}) => {

 
  const addressFinder1 = React.createRef();

  /* state */
 
  /* state */
  var widget;
  const handleLoadWidget = () => {
    
    widget = new window.AddressFinder.Widget(
      document.getElementById('addressFinder1'),
      '9H6QCRML43EPJDWXFNTV',
      'NZ'
    );
   
    
    if (Object.keys(widget).length === 0){
      return false;
    }
    widget.on('result:select', (fullAddress, metaData) => {
      const selected = new window.AddressFinder.NZSelectedAddress(fullAddress, metaData);
      handle(selected);
    
    });
  }

  useEffect(() => {
    var script = document.createElement('script');
    script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
    script.async = true;
    script.onload = handleLoadWidget
    document.body.appendChild(script);
  }, []);

  return <Inputs.Text type="search" id='addressFinder1' className='formInput' placeholder="Find address here..." ref={addressFinder1} />

})

export default account;
