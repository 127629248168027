import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */
import { LocalStorage } from '../../../lib-atomic-functions';
/* stores */
import PinUpdateStore from './store';
/* stores */

const index = ({CONFIG}) => {
  const accountPinUpdateContext  = useContext(PinUpdateStore);
   const cardNumber = LocalStorage.getLS('cardNumber')
 
  const accountPinUpdateMethod = async ({token, body}) => {
    let urlfinal = CONFIG.application.account.api.endpoints.updatePin.url + token;
    let method = CONFIG.application.account.api.endpoints.updatePin.method
    

    if(cardNumber) {
    urlfinal = CONFIG.application.account.api.endpoints.updatePassword.url;
    method = CONFIG.application.account.api.endpoints.updatePassword.method
    CONFIG.application.account.api.headers.Authorization = token;
    }
   
  console.log("cardNumber",urlfinal,method,CONFIG)
    accountPinUpdateContext.setLoading({loading: true, status: false, data: false});
    const fetch = await Fetch({
      url: urlfinal,
      config: {
        method: method,
        headers: CONFIG.application.account.api.headers,
        body: JSON.stringify(body)
      }
    });
    
    accountPinUpdateContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { accountPinUpdateContext, accountPinUpdateMethod }
}

export default index;
