import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { Buttons } from '../../lib-atomic';
import { ModulePaperWrapper,
  ModulePaperCreditPlusWrapper,
  ModulePaperCreditMinusWrapper,
  ModulePaperInfoWrapper
 } from '../../lib-atomic-design-section';
 import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* modules */
import AccountMenu from '../../app-module-account-menu/default';
import AccountProfileInfo from '../../app-module-account-profile-info/default';
import AccountProfileDetails from '../../app-module-account-profile-details/default';
import AccountFooter from '../../app-module-account-footer/default';
import TandC from '../../app-module-account-tnc/default';
/* modules */

/* sections */
import Title from './title';
import Loader from '../loader';
import AccountForm from '../form/account';
/* sections */

/* functions  */
import { AccountDetail } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';

/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LOGOSMALL = CONFIG.setup.assets.logo_small;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */

const index = observer((props) => {

  const LS = LocalStorage.getLS();
  const { login } = LS;
  if (!login) return <Redirect to='/u/logout' />

  /* state */
  const { accountDetailContext, accountDetailMethod } = AccountDetail({CONFIG});

  useEffect(() => {
    accountDetailMethod(login);
  }, []);

  useEffect(() => {
    if (accountDetailContext.expireToken) props.history.push('/u/landing-token-expire');
  }, [accountDetailContext.expireToken]);
  /* state */

  if (accountDetailContext.loading) return <Loader />
  if (!accountDetailContext.data) return <Loader />
  if (accountDetailContext.expireToken) return <Loader /> // redirect

  /* data props */
  const accountData = { account: accountDetailContext.data, props }
  const menuData = { name: "Vanessa McNab", props }
  const submitButton = { size: "small" }
  /* data props */

  return <Fragment>

    <AccountMenu {...menuData} />

    <Container maxWidth="sm">

    <AccountProfileInfo />

    <Title />

    <ModulePaperWrapper>
      <AccountForm {...accountData} />
    </ModulePaperWrapper>

    <br/>
    <br/>
    <br/>
    <div>&nbsp;</div>
    <br/>

    </Container>
    <TandC {...props}/>
    <AccountFooter {...props} />

  </Fragment>

})

export default index;
