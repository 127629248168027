import React from 'react'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

const ttile = props => {
  return <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" gutterBottom>
    Account
  </Typography>
}

export default ttile;
