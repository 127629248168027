import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { Buttons } from '../../lib-atomic';
import { ModulePaperWrapper,
  ModulePaperCreditPlusWrapper,
  ModulePaperCreditMinusWrapper,
  ModulePaperInfoWrapper
 } from '../../lib-atomic-design-section';
/* packages */

/* modules */
import AccountMenu from '../../app-module-account-menu/default';
import AccountProfileInfo from '../../app-module-account-profile-info/default';
import AccountProfileDetails from '../../app-module-account-profile-details/default';
import AccountFooter from '../../app-module-account-footer/default';
import TandC from '../../app-module-account-tnc/default';
/* modules */

/* section */
import Title from './title';
import Title2 from './title2';
/* section */

import CONFIG from '../config';
import ReactGA from 'react-ga';
function initializeReactGA(props1) {

  
  // const {traderId}= props1
  ReactGA.set({page:window.location.pathname})

  ReactGA.initialize('UA-151657300-1')
  
      
  ReactGA.pageview(window.location.pathname);

}
/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LOGOSMALL = CONFIG.setup.assets.logo_small;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
const useStyles = makeStyles(theme => ({
  button: {
    textTransform: "inherit !important",
    
  },
  button2: {
    textTransform: "inherit !important",
    float: "right"
  },
}));

/* CONSTANTS */

const index = observer((props) => {
  initializeReactGA();

  const classes = useStyles();

  /* data props */
  const menuData = { name: "Vanessa McNab", props }
  const submitButton = { size: "small" }
  /* data props */

  return <Fragment>

    <AccountMenu {...menuData} />

    <Container maxWidth="sm">

    <AccountProfileInfo />

<br/>
    <Title />
    <Link to="/account/details/edit" style={{textDecoration: 'none'}}>
    <Buttons.Normal {...submitButton} className={classes.button}>
       <span  style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}}>Edit account</span>
    </Buttons.Normal></Link> 
    <Link to="/account/details/pin/reset" style={{textDecoration: 'none'}}>
    <Buttons.Normal {...submitButton} className={classes.button2}>
    <span  style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}}>  Change PIN</span>
    </Buttons.Normal></Link>

    <AccountProfileDetails {...props} />

    {/* <Title2 /> */}

   

    <br/>
    <br/>
    <br/>
    <div>&nbsp;</div>
    <br/>

    </Container>
    <TandC {...props}/>
    <AccountFooter {...props} />

  </Fragment>

})

export default index;
