import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'
import Container from '@material-ui/core/Container';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
/* packages */

/* modules */

/* modules */

/* sections */
import LoginTitle from './title';
import LoginNav from './nav';
import LoginForm from '../form/login';
/* sections */
import AccountFooter from '../../app-module-account-footer/default';
import TandC from '../../app-module-account-tnc/default';
import CONFIG from '../config';

// google Analytics


/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
/* mui */

import ReactGA from 'react-ga';
function initializeReactGA(props1) {

  
  // const {traderId}= props1
  ReactGA.set({page:window.location.pathname})

  ReactGA.initialize('UA-151657300-1')
  
      
  ReactGA.pageview(window.location.pathname);

}
/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
}));
/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
/* CONSTANTS */


const index = (props) => {
  initializeReactGA();
  const classes = useStyles();
  return <Fragment>

 
 
    <Typography style={{height: "87px", marginTop: "19px"}}  align="center" >

      <img  className={classes.paper} src={LOGO} />
    </Typography>
    
    <ModulePaperWrapper>
      <br/>
  
      <LoginTitle />
      <br/>
      <LoginForm {...props} />
      <br/>
      <LoginNav />
    </ModulePaperWrapper>
   
 
  </Fragment>

}

export default index;
