import Login from '../components/app-module-account-login';
import Registration from '../components/app-module-account-registration';
import Logout from '../components/app-module-account-landing-user-logout';
import LoginLogout from '../components/app-module-account-landing-login-logout';
import TokenExpire from '../components/app-module-account-landing-token-expire';
import RegistrationExist from '../components/app-module-account-landing-registration-exist';
import NonRegistered from '../components/app-module-account-landing-non-registered';
import RegistrationPending from '../components/app-module-account-landing-registration-pending';
import RegistrationOnHold from '../components/app-module-account-landing-registration-on-hold';
import RegistrationTerminated from '../components/app-module-account-landing-registration-terminated';
import RegistrationSendActivation from '../components/app-module-account-landing-registration-send-activation';
import PinResetLoggedIn from '../components/app-module-account-landing-pin-reset-logged-in';
import PinUpdateLanding from '../components/app-module-account-landing-pin-update';
import PinChangeLanding from '../components/app-module-account-landing-pin-change';
import PinReset from '../components/app-module-account-pin-reset';
import PinUpdate from '../components/app-module-account-pin-update';
import PinChange from '../components/app-module-account-pin-change';
import RegistrationActivation from '../components/app-module-account-landing-registration-activation';
import TopUp from '../components/app-module-account-landing-topup';

export default [
  Login,
  Registration,
  Logout,
  LoginLogout,
  TokenExpire,
  RegistrationExist,
  PinChangeLanding,
  NonRegistered,
  RegistrationPending,
  RegistrationOnHold,
  RegistrationTerminated,
  RegistrationSendActivation,
  PinResetLoggedIn,
  PinUpdateLanding,
  PinReset,
  PinUpdate,
  PinChange,
  RegistrationActivation,
  TopUp
]
