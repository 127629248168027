import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */
import { makeStyles } from '@material-ui/core/styles';
/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { Buttons } from '../../lib-atomic';
/* packages */

import CONFIG from '../config';

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
}));
/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */

const index = observer((props) => {

  const linkButton = { size: "small" }
  const classes = useStyles();
  return <Fragment>
    <Typography  style={{height: "87px", marginTop: "19px"}}  align="center" gutterBottom>
      <img  className={classes.paper} src={LOGO} />
    </Typography>
    <ModulePaperWrapper>
      {/* <img src={LANDINGGRAPHICS} width="100%" /> */}
      <br/>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" gutterBottom>
      <b>This card is not registered</b>.
      </Typography>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2">
       To register your card, please click on the link below.
      </Typography>
      <br/>
      <Link to="/u/registration" style={{textDecoration: 'none'}}> 
      <Buttons.Normal style={{marginRight: "2px!important"}} {...linkButton} >
      <span  style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}}>Register your card</span>
      </Buttons.Normal>
      </Link>
      <Buttons.Normal {...linkButton} >
        <Link to="/u/logout">Back</Link>
      </Buttons.Normal>
    </ModulePaperWrapper>
  </Fragment>

})

export default index;
