import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import EmailAttrStore from './store';
/* stores */

const index = ({CONFIG}) => {
  const accountEmailAttrContext  = useContext(EmailAttrStore);
  const accountEmailAttrMethod = async ({token, traderId}) => {
    accountEmailAttrContext.setLoading({loading: true, status: false, data: false});
    CONFIG.application.account.api.headers.Authorization = token;
    const fetch = await Fetch({
      url: CONFIG.application.account.api.endpoints.emailAttr.url + traderId + "/emails",
      config: {
        method: CONFIG.application.account.api.endpoints.emailAttr.method,
        headers: CONFIG.application.account.api.headers
      }
    });
    accountEmailAttrContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { accountEmailAttrContext, accountEmailAttrMethod }
}

export default index;
