import React from 'react';

import Config from './config';
import Routes from './routes';
import Links from './links';
import Launcher from './launcher';
import App from './app';

/* packages */
import Packages from './packages';
const {  Modules } = Packages;
/* packages */

const Dashboard = {
  dashboard: {
    Config,
    Routes,
    Links,
    Launcher,
    App
  }
}

const Applications = { ...Dashboard, ...Modules }

export default {
  Applications
};
