import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */
import AccountFooter from '../../app-module-account-footer/default';
import TandC from '../../app-module-account-tnc/default';
/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
/* packages */
// google Analytics
import ReactGA from 'react-ga';
import { LocalStorage } from '../../lib-atomic-functions';
/* modules */

/* sections */
import RegTitle from './title';
import RegNav from './nav';
import RegStepper from './stepper';
/* sections */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CONFIG from '../config';

function initializeReactGA(props1) {
  
  
  ReactGA.set({page:window.location.pathname})

  ReactGA.initialize('UA-151657300-1');
  ReactGA.pageview(window.location.pathname);

}
/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
}));
const index = observer((props) => {
  initializeReactGA();
  const classes = useStyles();
  return <Fragment>
    
    <Typography style={{height: "87px", marginTop: "19px"}}  align="center" >
      <img className={classes.paper} src={LOGO} />
    </Typography>
    <ModulePaperWrapper>
      
      <br/>

<br/>
      {/* <br/> */}
      <RegStepper {...props} />
      <br/>
      <RegNav />
    </ModulePaperWrapper>


    <br/>
    <br/>
    <br/>
    <div>&nbsp;</div>
    <br/>
    
   
  </Fragment>

})

export default index;
