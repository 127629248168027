import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Landing Registration Pending";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': setup.setup.thor.moduleCode,
          'Authorization': null
        },
        endpoints: {
        
          emailResend: {
            doc: 'https://countdown-uat.txweb.site/members/resend',
            url: setup.setup.api.countdown_v1.url[environment] + '/members/resend',
            method: 'POST'
          }
        }
      },
 
      objects: {
        demo: { name: "demo", initial: "", label: "Demo", helperText: false, rules: ["isNotNull"] },
      },
      responses: {
        "sucess": "this is a demo response"
      }
    }
  },
  routes: {
    label: "Registration Pending",
    prefix: "/u/landing-registration-pending"
  },
  setup
}

export default config;
