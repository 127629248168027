import React from 'react'
import { Link } from 'react-router-dom';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */
import { makeStyles } from '@material-ui/core/styles';
/* packages */
import { ModuleWrapperMain, ModuleWrapperFull } from '../../lib-atomic-design-section';
/* packages */
const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
}));
const nav = props => {
  const classes = useStyles();
  return <ModuleWrapperMain>
  <ModuleWrapperFull>
   
  
  </ModuleWrapperFull>
  </ModuleWrapperMain>
}

export default nav;
