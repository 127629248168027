import React from 'react'

/* packages */
import CONFIG from './config';
import Packages from './packages';
const {  Modules } = Packages;
/* packages */

const Links = Modules.map((Module, index) => {
  const { Links } = Module;
  if (Links) return <Links key={index} />
});

const Atom =  props => {
  const { label, prefix } = CONFIG.routes;
  return <div>
    <div className="thq-module">
      <div>
        {label}
      </div>
      { Links }
    </div>
  </div>
}

export default Atom;
