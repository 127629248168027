import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import RegisterStore from './store';
/* stores */

const index = ({CONFIG}) => {
  const accountRegisterContext  = useContext(RegisterStore);
  const accountRegisterMethod = async ({body}) => {
    accountRegisterContext.setLoading({loading: true, status: false, data: false});
    const fetch = await Fetch({
      url: CONFIG.application.account.api.endpoints.register.url,
      config: {
        method: CONFIG.application.account.api.endpoints.register.method,
        headers: CONFIG.application.account.api.headers,
        body
      }
    });
    accountRegisterContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { accountRegisterContext, accountRegisterMethod }
}

export default index;
