import React, { Fragment,useState } from 'react';

import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
/* atoms */
import { Invalid } from '../feedbacks';
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

const password = (props,visible) => {
  const [vv, setvv] = useState(visible)
  console.log("Props",props)
  const placeHolder = props.label
  const handleClickShowPassword = () => {
    setvv(!vv)
  }
  
  return <Fragment>
   <FormControl  variant="outlined" fullWidth>
   <InputLabel htmlFor="outlined-adornment-password"  margin="dense">
            {placeHolder}
          </InputLabel>
     <OutlinedInput
        id="outlined-adornment-password"
        type={vv ? 'password' : 'text'}
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder ={placeHolder}
        {...props}
       label = {placeHolder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              InputProps={{ style: { backgroundColor: '#d3d3d352' } }}
              onClick={handleClickShowPassword}
              edge="end"
            >
              {vv ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        labelWidth={0}
      />    <Invalid {...props} />
      </FormControl>
  </Fragment>
}

export default password;
