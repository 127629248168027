import React, { useContext } from 'react';

/* packages */
import { Fetch } from '../../../lib-atomic-functions';
/* packages */

/* stores */
import ActivationStore from './store';
/* stores */

const index = ({CONFIG}) => {
  const accountActivationContext  = useContext(ActivationStore);
  const accountActivationMethod = async ({body}) => {
    accountActivationContext.setLoading({loading: true, status: false, data: false});
    const fetch = await Fetch({
      url: CONFIG.application.account.api.endpoints.activation.url,
      config: {
        method: CONFIG.application.account.api.endpoints.activation.method,
        headers: CONFIG.application.account.api.headers,
        body
      }
    });
    accountActivationContext.setLoading({loading: false, status: fetch[0], data: fetch[1]});
  }

  return { accountActivationContext, accountActivationMethod }
}

export default index;
