import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { Buttons } from '../../lib-atomic';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CONFIG from '../config';
const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
}));
/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */

const index = observer((props) => {

  const { login, emailAddress } = LocalStorage.getLS();
  // const { emailAddress } = login;
  const linkButton = { size: "small" }
  const classes = useStyles();
  return <Fragment>
    <Typography style={{height: "87px", marginTop: "19px"}} align="center" >
      <img  className={classes.paper}  src={LOGO} />
    </Typography>
    <ModulePaperWrapper>
      {/* <img src={LANDINGGRAPHICS} width="100%" /> */}
      <br/>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" gutterBottom>
        You're almost there.
      </Typography>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
        We've sent you an email with an <b>verification link</b> to this email address <b>{emailAddress}</b>
      </Typography>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
        Kindly check your email <b>inbox</b> or <b>spam</b> folder and click on the link to activate your account.
      </Typography>
      <br/>
      {/* <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2">
        <Buttons.Normal {...linkButton} >
          <Link to="/u/login">Continue</Link>
        </Buttons.Normal>
      </Typography> */}
    </ModulePaperWrapper>
  </Fragment>

})

export default index;
