import React from 'react'
import moment from 'moment'

// import TextField from '@material-ui/core/TextField';

/* atoms */
import { Invalid } from '../feedbacks'
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const date = props => {
  const {
    formatDisplay,
    formatProcess,
    value,
    initial,
    name,
    label,
    onPick,
    doReset,
  } = props

  const [selectedDate, setSelectedDate] = React.useState(initial)

  if (selectedDate !== null && doReset) {
    setSelectedDate(null)
  }

  const handleDateChange = date => {
    if (!date) {
      date = ''
    } else {
      setSelectedDate(date)
      date = moment(date).utc().format()
      // onPick(name, moment(date).format("YYYY-MM-DD"));
      // console.log("date",date)
    }
    onPick(name, date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* disableToolbar */}
      <KeyboardDatePicker
        name={name}
        // label={label}
        placeholder={label}
        autoOk
        fullWidth
        variant="inline"
        inputVariant="outlined"
        format={formatDisplay}
        helperText={null}
        margin="dense"
        error={false}
        disableToolbar
        // id="date-picker-inline"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      {/* <Invalid {...props} /> */}
    </MuiPickersUtilsProvider>
  )
}

export default date
