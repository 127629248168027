/* development */
import List from '../../app-module-demo-component';

const Modules = [
  List
]

export default {
  Modules
}
