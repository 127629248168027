import React, { Fragment,useState } from 'react'
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'
import ReCAPTCHA from "react-google-recaptcha";

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* functions  */
import { PinReset } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';

/* CONSTANTS */
const RESETOBJECTS = CONFIG.application.account.objects;
/* CONSTANTS */

const reset = observer((props) => {

  /* state */
  const { accountPinResetContext, accountPinResetMethod } = PinReset({CONFIG});
  const [verified, setVerified] = useState(true);
  const [captchaToken, setCaptchaToken] = useState("");


  /* state */
  const callback = async (response) => {
    setCaptchaToken(response) 
    if(response){
     setVerified(false);
    }
  };

  const handlePinReset = async () => {
    await accountPinResetMethod({body: JSON.stringify(values), token:captchaToken});
    console.log(accountPinResetContext.data);
    if (accountPinResetContext.data.invalidToken) return false;
    if (accountPinResetContext.data.errorCode) return false;
    LocalStorage.addLS('emailAddress', values.emailAddress );
    props.history.push('/u/landing-pin-reset-logged-in');
  }


  const {
    values,
    onChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handlePinReset, RESETOBJECTS);

  const emailAddress = { ...RESETOBJECTS.emailAddress, error: error.emailAddress, value: values.emailAddress, onChange };
  const submitButton = { fullWidth: true }
  const failReset = { error: ["Please enter a valid email address"] }
  const backButton = { fullWidth: true,  }

  var size="normal"
  if (window.screen.availWidth<=1000){
  
  size = "compact"
  }

const style={
  color:'#007837',
}
 

  return <form onSubmit={onSubmit} noValidate>
  
    <Inputs.Text {...emailAddress} />
    { accountPinResetContext.data.invalidToken &&
      <Fragment>
      <Invalid {...failReset} />
      </Fragment>
    }
    { accountPinResetContext.data.errorCode &&
      <Fragment>
      <Invalid {...failReset} />
      </Fragment>
    }
    <div>&nbsp;</div>
    <div style={{textAlign: "-webkit-center"}}>
      <ReCAPTCHA 
        sitekey="6Le6i7cUAAAAAAVvoOKjPrVP6XKNTJGYXM9DPJhO"
        // sitekey="6LfFxqceAAAAAI9Kkg_YxtzzkQjJ_RBH7rgHxCw-"
        onChange={callback}
        size={size}
      />
    </div>
    <br/>
    {/* <Buttons.Normal {...submitButton}>Submit</Buttons.Normal> */}
    <Buttons.Normal {...submitButton} disabled={verified}>Submit</Buttons.Normal>
    <div>&nbsp;</div>
    <Link to="/u/login" style={style} > 
   <Buttons.Text {...backButton}>
   <span  style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}}>Back</span>
   </Buttons.Text>
   </Link>
  </form>

})

export default reset;
