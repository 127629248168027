import appConf from "../app-conf";
import isMinMax from "../lib-atomic-functions/form-validation/rules/isMinMax";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Account Profile Edit and Update";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': setup.setup.thor.moduleCode,
          'Authorization': null,
        },
        endpoints: {
          detail: {
            doc: 'https://countdown-uat.txweb.site/member/enquiry',
            url: setup.setup.api.countdown_v1.url[environment] + '/members/enquiry',
            method: 'GET'
          },
          update: {
            doc: 'https://countdown-uat.txweb.site/member/update',
            url: setup.setup.api.countdown_v1.url[environment] + '/members/update',
            method: 'PUT'
          }
        }
      },
      objects: {
        titleId: { name: "titleId", initial: "", label: "Title",  helperText: false, rules: false },
        firstName: { name: "firstName", initial: "", label: "First name", helperText: false, rules: false },
        lastName: { name: "lastName", initial: "", label: "Last name", helperText: false, rules: false },
        //birthDate: { name: "birthDate", initial: "", label: "Date of Birth", helperText: false, rules: false },
        // oneCard: { name: "oneCard", initial: "", label: "Onecard number", helperText: false, rules: ["isRequiredOneCard"], validationName:"onecard number",limit:13, minLimit:12, minmax_suffix: "digit number" },
        genderId: { name: "genderId", initial: "", label: "Gender", helperText: false, rules: false },
        emailAddress: { name: "emailAddress", initial: "", label: "Email", helperText: false, rules: false },
        mobileNumber: { name: "mobileNumber", initial: "", label: " ", helperText: false, rules: ["isMinMaxPhoneNumber" ], allow_null: true, minmax_suffix: "digit number", limit:10, minLimit:6, validationName:"title"},
        prefixMobileNumber: { name: "prefixMobileNumber", initial: " ", label: " ", helperText: false, rules: false, max:"10"},
        prefixPhone2: { name: "prefixPhone2", initial: " ", label: " ", helperText: false, rules: false },
        phone2: { name: "phone2", initial: "", label: " ", helperText: false, rules: ["isMinMaxPhoneNumber"], allow_null: true, minmax_limit: 7, minmax_suffix: "digit number",  limit:7, minLimit:7, },
        // phone1: { name: "phone1", initial: "", label: "Home phone", helperText: false, rules: [""] },
        physicalAddress1: {  name: "physicalAddress1", initial: "", label: "Address 1", helperText: false, rules: false },
        physicalAddress2: {  name: "physicalAddress2", initial: "", label: "Address 2", helperText: false, rules: false },
        physicalSuburb: {  name: "physicalSuburb", initial: "", label: "Suburb", helperText: false, rules: false },
        physicalCity: {  name: "physicalCity", initial: "", label: "City", helperText: false, rules: false },
        physicalPostCode: {  name: "physicalPostCode", initial: "", label: "Postcode", helperText: false, rules: false },
        address1: { disabled: true, name: "address1", initial: "", label: "Address 1", helperText: false, rules: false, validationName:"title" },
        address2: { disabled: true, name: "address2", initial: "", label: "Address 2", helperText: false, rules: false , validationName:"title"},
        suburb: { disabled: true, name: "suburb", initial: "", label: "Suburb", helperText: false, rules: false , validationName:"title"},
        city: { disabled: true, name: "city", initial: "", label: "City", helperText: false, rules: false , validationName:"title"},
        postcode: { disabled: true, name: "postcode", initial: "", label: "Postal Code", helperText: false, rules: false },
      }
    }
  },
  routes: {
    label: "Profile Edit",
    prefix: "/account/details/edit"
  },
  setup
}

export default config;
