const passwordCheck = (data) => {
 
    let value = data.value;
    // value = value.trim();
    // const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{8,}/;

    const passwordLength =      value.length;
    const uppercasePassword =   uppercaseRegExp.test(value);
    const lowercasePassword =   lowercaseRegExp.test(value);
    const digitsPassword =      digitsRegExp.test(value);
    const specialCharPassword = specialCharRegExp.test(value);
    const minLengthPassword =   minLengthRegExp.test(value);

    let errMsg ="";
    if(passwordLength===0){
        errMsg="Password is empty";
}else if(!uppercasePassword){
        errMsg="Please include at least one uppercase letter";
}else if(!lowercasePassword){
        errMsg="Please include as least one lowercase letter";
}else if(!digitsPassword){
        errMsg="Please include at least one digit";
}else if(!specialCharPassword){
        errMsg="Please include at least one special character";
}else if(!minLengthPassword){
        errMsg="Please include at least 8 characters";
}else{
    errMsg = true;
}
    return errMsg;
  }
  
  export default passwordCheck;
  