const isNotNull = (data) => {

  
  let value = data.value;
  const title = data.config.validationName;
  value = value.trim();
  return (value === "" || value === null) ? "Please enter your " + title : true;
}

export default isNotNull;
