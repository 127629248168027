import React from 'react'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

const ttile = props => {
  return <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
    We will send you a link to reset your Password.
  </Typography>
}

export default ttile;
