import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { Buttons } from '../../lib-atomic';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
/* sections */
import Loader from '../loader';
/* sections */

/* functions  */
import { AccountRegisterActivation } from '../../app-module-account-container/functions'
/* functions  */


import CONFIG from '../config';

/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
}));
const index = observer((props) => {

  LocalStorage.removeLS();

  /* state */
  const { accountActivationContext, accountActivationMethod } = AccountRegisterActivation({CONFIG});
  useEffect(() => {
    handleEmailActivation();
  }, []);
  /* state */

  const handleEmailActivation = async () => {
    const { token } = props.match.params;
    await accountActivationMethod({body: JSON.stringify({token})})
  }

  /* activation responses */
  const errorResponse = {
    title: "Great!",
    content: "Your account has been successfully activated, you are now ready to start saving for Christmas.",
    content2:"We've sent an email with all the details you need to start topping up your account",
    link: "Login to your account"
  };
  if (accountActivationContext.data.invalidToken) {
    if (accountActivationContext.data.invalidError.errorCode) {
      const { developerErrorMessage } = accountActivationContext.data.invalidError;
      if (developerErrorMessage.includes("Trader is already activated.")) {
        errorResponse.title = "Oops!"
        errorResponse.content = "Your email address has already been verified and your account has been activated"
        errorResponse.link = "Login to your account"
      } else if (developerErrorMessage.includes("Parameter trader_id is null")) {
        errorResponse.title = "Oops!"
        errorResponse.content = "Unable to activate your account. Please contact customer service with regards to this matter."
        errorResponse.link = "Continue"
      }
     }
   }
  /* activation responses */
  const classes = useStyles();

  const linkButton = { size: "small" }

  if (accountActivationContext.loading) return <Fragment>
   <Typography style={{height: "87px", marginTop: "19px"}}  align="center" >
      <img className={classes.paper} src={LOGO} />
    </Typography>
    <Loader />
  </Fragment>
  return <Fragment>
    <Typography style={{height: "87px", marginTop: "19px"}}  align="center" >
    <img  className={classes.paper} src={LOGO} />
    </Typography>
    <ModulePaperWrapper>
      {/* <img src={LANDINGGRAPHICS} width="100%" /> */}
      <br/>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" gutterBottom>
        <b>{ errorResponse.title }</b>
      </Typography>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
        { errorResponse.content }
      </Typography>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
        { errorResponse.content2 }
      </Typography>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2">
        <Buttons.Normal {...linkButton} >
          <Link to="/u/logout">{ errorResponse.link }</Link>
        </Buttons.Normal>
      </Typography>
    </ModulePaperWrapper>
  </Fragment>

})

export default index;
