import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
/* packages */
import TandC from '../../app-module-account-tnc/default';
/* modules */
import AccountFooter from '../../app-module-account-footer/default';
/* modules */
import { makeStyles } from '@material-ui/core/styles';
/* sections */
import ResetTitle from './title';
import ResetText from './text';
import ResetNav from './nav';
import ResetForm from '../form/reset';
/* sections */

import CONFIG from '../config';

/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
}));
const index = observer((props) => {
  const classes = useStyles();
  return <Fragment>
    <Typography align="center" style={{height: "87px", marginTop: "19px"}}>
      <img className={classes.paper} src={LOGO} />
    </Typography>
    <ModulePaperWrapper>
      <br/>
      <ResetTitle />
      <br/>
      <ResetText />
      <ResetForm {...props} />
      <br/>
     
    </ModulePaperWrapper>
   

  </Fragment>

})

export default index;
