import React, { Fragment } from 'react';

import { Skeletons } from '../../lib-atomic';

const Loader = props => {
  return <Fragment>
    <Skeletons.Xlarge />
    <Skeletons.Xlarge />
    <Skeletons.Xlarge />
  </Fragment>
}

export default Loader;
