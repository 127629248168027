import React, {useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'
import ReCAPTCHA from "react-google-recaptcha";
/* mui */
import FormHelperText from '@material-ui/core/FormHelperText';

// google Analytics
import ReactGA from 'react-ga';

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage, SessionStorage } from '../../lib-atomic-functions';
/* packages */

/* functions  */
import { AccountLogin, AccountDetail,CaptchaToken } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';
import { intercept } from 'mobx';

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.objects;
const LOGINFAILRESPONSE = CONFIG.application.account.responses.fail;
const ENVIRONMENT = CONFIG.environment;
const MEMBERSTATUS = CONFIG.setup.setup.thor.memberStatus[ENVIRONMENT];
/* CONSTANTS */


function initializeReactGA(props1) {
  if(props1){
    
    var userId = props1.traderId;
  
  // const {traderId}= props1
  ReactGA.set({page:window.location.pathname})

  ReactGA.initialize('UA-151657300-1', {
    gaOptions: {
      userId: userId,
      dimension1:userId
    }});
      
  ReactGA.pageview(window.location.pathname);
}
}

const login = observer((props) => {

  initializeReactGA();
 
  LocalStorage.removeLS();
  
  /* state */
  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(true);
  const [captchaToken, setCaptchaToken] = useState("");

  const { accountLoginContext, accountLoginMethod } = AccountLogin({CONFIG});
  const { accountDetailContext, accountDetailMethod } = AccountDetail({CONFIG});
  const { captchaTokenContext, captchaTokenMethod } = CaptchaToken({CONFIG});

  /* state */

  const callback = async (response) => {
   setCaptchaToken(response) 
   if(response){
    setVerified(false);
  }
  }
  
  const handleLogin = async () => {
 
 
    const { history } = props;
  
    await accountLoginMethod({body: JSON.stringify(values),token:captchaToken});
    
    await accountDetailMethod(accountLoginContext.data); // {token}

    LocalStorage.addLS('token',accountLoginContext.data);

    LocalStorage.addLS('cardNumber',values.username);

    if(values.password.length == 4){
      LocalStorage.addLS('isOldUser','true');
    }else{
      LocalStorage.addLS('isOldUser','false');
    }
   
    if (accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:5"||accountLoginContext.data.developerErrorMessage=="Card Terminated. Please Contact the store↵"||accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:9"){
      LocalStorage.addLS('login', { ...accountLoginContext.data, username: values.username });
      props.history.push('/u/landing-registration-terminated');
    }

    if (accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:11"){
      LocalStorage.addLS('login', { ...accountLoginContext.data, username: values.username });
      props.history.push('/u/landing-registration-on-hold');
    }
    if (accountLoginContext.data.errorMessage || accountDetailContext.data.errorMessage) return false;

    const userEmailAddress =accountDetailContext.data.traderDetails.emailAddress;
    LocalStorage.addLS('emailAddress',userEmailAddress);

    const { memberStatus, statusId } = accountDetailContext.data.traderDetails;
    if (MEMBERSTATUS[statusId] === "Unregistered") {
      // LocalStorage.addLS('login', { ...accountLoginContext.data, username: values.username });
      props.history.push('/u/landing-non-registered');
    }
    
    else if (MEMBERSTATUS[statusId] === "Pending") {
      LocalStorage.addLS('login', { ...accountLoginContext.data,  pending: true, username: values.username });
      props.history.push('/u/landing-registration-pending');
    } else if (MEMBERSTATUS[statusId] === "OnHold") {
      LocalStorage.addLS('login', { ...accountLoginContext.data, username: values.username });
      props.history.push('/u/landing-registration-on-hold');
    } else if (MEMBERSTATUS[statusId] === "Terminated") {
      LocalStorage.addLS('login', { ...accountLoginContext.data, username: values.username });
      props.history.push('/u/landing-registration-terminated');
    }
    
    else if (MEMBERSTATUS[statusId] === "Registered") {
      
      LocalStorage.addLS('login', { ...accountLoginContext.data, username: values.username });
      LocalStorage.addLS('detail', accountDetailContext.data);
      // SessionStorage.addLS('detail', accountDetailContext.data);
      props.history.push('/u/landing-login-logout');
    }
    initializeReactGA(accountDetailContext.data.traderDetails);
  }

  const {
    values,
    onChange,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleLogin, LOGINOBJECTS);

  const username = { ...LOGINOBJECTS.username, error: error.username, value: values.username, onChange, autoComplete: "new-username" };
  const password = { ...LOGINOBJECTS.password, error: error.password, value: values.password, onChange, autoComplete: "new-password" };
  const submitButton = { fullWidth: true }
  const failLogin = { error: [LOGINFAILRESPONSE] }
  
  var size="normal"
  if (window.screen.availWidth<=1000){
  
  size = "compact"
  }

  
  return <form onSubmit={onSubmit} noValidate > 
       { accountLoginContext.data.errorCode=="AUTH:AUTH_GULD:19" &&
      <Fragment>
      <Invalid  /><p style={{fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif', color:'red'}}>You have reached the maximum attempts to login, you can try again in one hour.<br/><br/>If you need to check your balance immediately, you can contact our team on 0800 40 40 40</p>
      <br/>
      </Fragment>
    }
 
      { accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:7" &&
      <Fragment>
      <Invalid  /><p style={{fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif', color:'red'}}>This card has been closed<br/><br/>please contact 0800 40 40 40 if you need further assistance</p>
      <br/>
      </Fragment>
    }
    { accountLoginContext.data.errorCode=="AUTH:VAL_TRADER_IDENT:3" || accountLoginContext.data.errorCode=="-2" &&
      <Fragment>
      <Invalid  /><p style={{fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif', color:'red'}}>Invalid card number or Password</p>
      <br/>
      </Fragment>
    }
     { accountLoginContext.data.errorCode=="TX_AUTH:LOGIN_USR:2" &&
      <Fragment>
      <Invalid  /><p style={{fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif', color:'red'}}>Account is not active</p>
      <br/>
      </Fragment>
    }  
       { accountLoginContext.data.errorCode=="TX_AUTH:LOGIN_USR:4" &&
      <Fragment>
      <Invalid  /><p style={{fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif', color:'red'}}>Invalid card number or Password</p>
      <br/>
      </Fragment>
    }  
   
   
    <Inputs.Text {...username} /><br/>
    <Inputs.Password {...password} />
    <br/>
    <div>&nbsp;</div>
    <div style={{textAlign: "-webkit-center"}}>
    <ReCAPTCHA 
      sitekey="6Le6i7cUAAAAAAVvoOKjPrVP6XKNTJGYXM9DPJhO"
      // sitekey="6LfFxqceAAAAAI9Kkg_YxtzzkQjJ_RBH7rgHxCw-"
      onChange={callback}
      size={size}
    />
  </div>
    <br/>

    <Buttons.Normal style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} {...submitButton} disabled={verified}>Next</Buttons.Normal>
  </form>

})

export default login;
