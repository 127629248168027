import React from 'react'
import Moment from 'react-moment';

/* mui */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
/* mui */
import moment from 'moment/min/moment-with-locales';
import 'moment-timezone';
import 'moment/locale/en-nz';
// moment/locale/fr
/* packages */
import { ModulePaperCreditPlusWrapper } from '../../lib-atomic-design-section';
/* packages */
import NumberFormat from 'react-number-format';
const index = ({storeName, transactionDate, creditValue,processedDate, purchaseValue}) => {

 
 const v =  processedDate.substring(0, 10);
 

  var value = Number(creditValue);

  var value2 =  value.toFixed(2) 
  
 

  return <ModulePaperCreditPlusWrapper style={{color:'white'}} >
    <Grid container spacing={2}>
      <Grid item xs>
        <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} variant="subtitle2" noWrap>
        <b>Bonus locked</b> <br/>
          <small><Moment locale="en" style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} format="DD/MM/YYYY">{ v }</Moment></small><br/>
        </Typography>
      </Grid>
      <Grid item >
        <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontWeight: 'bold'}} variant="subtitle1">
        <NumberFormat value={value2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          {/* <b>${ value2 }</b> */}
        </Typography>
      </Grid>
    </Grid>
  </ModulePaperCreditPlusWrapper>

}

export default index;
