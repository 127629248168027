import React from 'react';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  paper: {
    // backgroundColor: "#fafafa",
    padding: theme.spacing(3),
    textAlign: 'left',
    color: "#fff",
    marginTop: 15,
    marginBottom: 15,
    fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif',
    // backgroundColor: "#fff",
    backgroundSize: "cover",
    backgroundImage: "url(https://thq-mj-woolworths.s3.ap-southeast-2.amazonaws.com/assets/countdown/LOGGED+IN+Banner.png)",
    // width: "374px !important",
    backgroundRepeat: "round",
  }
}));
/* CONSTANTS */

const wrapper = props => {

  /* mui */
  const classes = useStyles();
  /* mui */

  return <Paper className={classes.paper}>
    { props.children }
  </Paper>
}

export default wrapper;
