import React, { Fragment } from 'react';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import { subYears } from 'date-fns';
/* atoms */
import { Invalid } from '../feedbacks';
/* atoms */

/*
: properties
name,
label,
value,
onChange,
helperText,
error
*/

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';

const date = props => {

  //const defaultValue = props.value ? props.value : new Date();
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [dateError, setDateError] = React.useState(false);

  const handleDateChange = (date) => {
    const minDate = subYears(new Date(), 18);

    if(date < minDate){
    setSelectedDate(date);
    setDateError(false);
    const { name, onPick } = props
    onPick(name, moment(date).format("YYYY-MM-DD"));
    }else{
      setDateError(true);
    }
  }

  return <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      {...props}
      defaultValue={date}
      placeholder="DD/MM/YYYY"
      fullWidth
      disableToolbar
      disableFuture
      openTo="year"
      variant="outline"
      format="dd/MM/yyyy"
      margin="dense"
      id="date-picker-inline"
     
      // views={["year", "month", "date"]}
      value={selectedDate}
      onChange={handleDateChange}
      minDate={subYears(new Date(), 118)}
      maxDate={subYears(new Date(), 18)}
      error={dateError}
      helperText={dateError ? "Please select a valid date" : ""}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      
      }}
    />
    <Invalid {...props} />
  </MuiPickersUtilsProvider>
}
// const date = (props) => {
//   return <Fragment>
//     <TextField {...props} defaultValue="2017-05-24" type="date" fullWidth variant="outlined" margin="dense" />
//     <Invalid {...props} />
//   </Fragment>
// }

export default date;
