import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */
import { makeStyles } from '@material-ui/core/styles';
/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { Buttons } from '../../lib-atomic';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

import CONFIG from '../config';

/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */const useStyles = makeStyles(theme => ({
    paper: {
      width: "100%",
      height: "auto"
    },
  }));

const index = observer((props) => {

  LocalStorage.removeLS(); // remove all local storages
  const linkButton = { size: "small" }
  
  const classes = useStyles();
  return <Fragment>
    <Typography style={{height: "87px", marginTop: "19px"}} align="center" gutterBottom>
      <img className={classes.paper} src={LOGO} />
    </Typography>
    <ModulePaperWrapper>
      {/* <img src={LANDINGGRAPHICS} width="100%" /> */}
      <br/>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" gutterBottom>
      <b>This card has been closed</b>.
      </Typography>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2">
      If needed you can contact our customer care team on <b>0800 40 40 40</b>.
      </Typography>
      <br/>
    <Link to="/u/login" style={{textDecoration: 'none'}}>
      <Buttons.Normal {...linkButton} >
      <span  style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}}> Back</span>
      </Buttons.Normal></Link>
    </ModulePaperWrapper>
  </Fragment>

})

export default index;
