const isPinChecklist = (data) => {

    let value = data.value;

    value = value.trim();
    const pattern = ['1234','2345','3456','4567','5678','6789','7890','0987','9876','8765','7654','6543','5432','4321','3210','2468','3579','1357','0000','1111','2222','3333','4444','5555','6666','7777','8888','9999'];

       
     return (!pattern.includes(value)) ?  true :"Pin must be unique. Please select a stronger a pin";
  }
  
  export default isPinChecklist;
  