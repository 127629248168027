import React from 'react'
import { Link, Redirect } from 'react-router-dom';
import { Buttons } from '../../lib-atomic';

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */
const submitButton = { size: "small"  }
var container={
  textAlign:'center',
  marginBottom: '10px',
}
var text1={
  fontFamily:'open_sansregular,Helvetica,Arial,sans-serif',
    float:'left'
}
var button1={
  position: "relative"
}
if (window.screen.height<800){
  text1={
    fontFamily:'open_sansregular,Helvetica,Arial,sans-serif',
    textAlign: 'center'
  }
  button1={
    position: "relative",
     textAlign: 'center'
  }
}

// console.log("window",window.screen.height);
const ttile = props => {
  return<Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" gutterBottom>
  Transactions
</Typography>

    
}

export default ttile;
