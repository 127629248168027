import React from 'react';
import AccountFooter from '../../components/app-module-account-footer/default';
import TandC from '../../components/app-module-account-tnc/default';
/* mui */
import Container from '@material-ui/core/Container';
/* mui */
import AccountMenu from '../../components/app-module-account-menu-login-page/default';
import Imports from './imports';
import Header from './header';
import Footer from './footer';

import appConf from '../../components/app-conf';
const Themes = appConf[process.env.REACT_APP_CLIENT].themes;

const thqLanding = props => {
  return <Themes>
    <AccountMenu />
    <Container  maxWidth="xs">
      <Imports {...props} />
     
    </Container>
    <TandC {...props}/>
    
    <AccountFooter {...props} />
  </Themes>

}

export default thqLanding;
