import React, {Fragment, useEffect, useContext, useState} from 'react'
import {Link, Redirect} from 'react-router-dom';
import {observer, useObserver} from 'mobx-react-lite';

/* mui */
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MoneyIcon from '@material-ui/icons/Money';
/* mui */
import Pagination from '@material-ui/lab/Pagination';
/* packages */
import {Buttons, Inputs} from '../../lib-atomic';
import {ModulePaperWrapper, ModulePaperCreditPlusWrapper, ModulePaperCreditMinusWrapper, ModulePaperInfoWrapper} from '../../lib-atomic-design-section';
import {LocalStorage} from '../../lib-atomic-functions';
/* packages */

/* modules */
import {FormHook} from '../../lib-atomic-functions'
import AccountMenu from '../../app-module-account-menu/default';
import AccountProfileInfo from '../../app-module-account-profile-info/default';
import AccountFooter from '../../app-module-account-footer/default';
import AccountTransactionInfoList from '../../app-module-account-transaction-info-list/default';
import TandC from '../../app-module-account-tnc/default';
/* modules */
// google Analytics
import ReactGA from 'react-ga';
/* section */
import Title from './title';
/* section */

/* functions  */
import {AccountTransaction} from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';

/* CONSTANTS */
const TRANSACTION = CONFIG.application.account.objects;
const LOGO = CONFIG.setup.assets.logo;
const LOGOSMALL = CONFIG.setup.assets.logo_small;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
const useStyles = makeStyles(theme => ({
    button: {
        // textTransform: "inherit !important",
        marginBottom: "8px!important"
    }
}));
/* CONSTANTS */
function initializeReactGA(props1) {

    ReactGA.set({page: window.location.pathname})

    ReactGA.initialize('UA-151657300-1');
    ReactGA.pageview(window.location.pathname);

}
const index = observer((props) => {
    initializeReactGA();

    const classes = useStyles();

    /* data props */
    const menuData = {
        name: "Vanessa McNab",
        props
    }
    const submitButton = {
        size: "small"
    }

    const [page,
        setPage] = React.useState(1);
    const handlePage = (event, value) => {
        setPage(value);
    };
    /* data props */
    const handleSubmit = async() => {}

    console.log("props1212", props)

    const {values, onPick, error} = FormHook.useFormHook(handleSubmit, TRANSACTION)

    const startDob = {
        ...TRANSACTION.startdob,
        // error: error.startdob,
        value: values.startdob,
        formatDisplay: 'dd/MM/yyyy',
        onPick
    }
    const EndDob = {
        ...TRANSACTION.enddob,
        // error: error.enddob,
        value: values.enddob,
        formatDisplay: 'dd/MM/yyyy',
        onPick
    }
    if (values.enddob != "") {
      var pageination = page - 1;
        var methodData = {

            query: "?startDate=" + values.startdob + "&endDate=" + values.enddob + "&page=" + pageination + "&pageSize=25"

        }
    }
    if (page != 0 && values.enddob == "") {
        var pageination = page - 1;
        var methodData = {

            query: "?page=" + pageination + "&pageSize=25"

        }
    }
    return <Fragment>

        <AccountMenu {...menuData}/>

        <Container maxWidth="sm">

            <AccountProfileInfo/>
            {/* <div
                style={{
                backgroundColor: '#62bb47',
                border: '6px solid #007837'
            }}>
                <p
                    style={{
                    fontSize: 'small',
                    textAlign: 'center',
                    fontFamily: 'open_sansregular, Helvetica, Arial, sans-serif',
                    fontWeight: 'bold'
                }}>Our
                    Christmas Club phone line is temporarily closed to provide more support to our
                    Customer Care team. If you have any queries about Christmas Club and can't find
                    the information on this page, please ask
                    <a href="https://www.woolworths.co.nz/store-finder">Olive, our friendly virtual assistant</a>
                </p>
            </div> */}
            <br/>

            <Title/>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={4} spacing={1}>
                    <Link
                        to="/account/balance"
                        style={{
                        textDecoration: 'none'
                    }}>
                        <Buttons.Normal {...submitButton} className={classes.button}>
                            <span
                                style={{
                                fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'
                            }}>View balance</span>
                        </Buttons.Normal>
                    </Link>
                </Grid>
                <Grid item xs={6} sm={4} spacing={1}>

                    <Inputs.TransactionDate {...startDob}/>

                </Grid>
                <Grid item xs={6} sm={4} spacing={1}>

                    <Inputs.TransactionDate {...EndDob}/>

                </Grid>
            </Grid>
            <br/>
            <AccountTransactionInfoList {...props} {...methodData}/>

            <br/>
            <Pagination
                count={10}
                page={page}
                onChange={handlePage}
                variant="outlined"
                color="primary"/>
            <div style={{
                marginBottom: "26px"
            }}></div>
            <br/>
            <br/>

        </Container>
        <TandC {...props}/>
        <AccountFooter {...props}/>

    </Fragment>

})

export default index;
