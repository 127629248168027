import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
/* packages */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CONFIG from '../config';
const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
}));
/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */

const index = observer((props) => {
  const classes = useStyles();
  return <Fragment>
     <Typography style={{height: "87px", marginTop: "19px"}}  align="center" >
    <img  className={classes.paper} src={LOGO} />
    </Typography>
    <ModulePaperWrapper>
      {/* <img src={LANDINGGRAPHICS} width="100%" /> */}
      <br/>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" gutterBottom>
        Your card has already been registered.
      </Typography>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2">
        <Link to="/u/login"><b>Continue to your account</b></Link>
      </Typography>
    </ModulePaperWrapper>
  </Fragment>

})

export default index;
