import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Account Pin Update";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': setup.setup.thor.moduleCode,
        },
        endpoints: {
          updatePin: {
           
            url: setup.setup.api.countdown_v1.url[environment] + '/password/',
           
            method: 'PUT'
          },
          updatePassword: {
           
            url: setup.setup.api.countdown_v1.url[environment] + '/members/changePassPin',
           
            method: 'POST'
          }
        }
      },
      objects: {
        password: { name: "password", initial: "", label: "Create Password", helperText: false, rules: ["passwordCheck"], allow_null: false, minmax_limit: 8,  validationName:"password"},
        password_confirm: { name: "password_confirm", initial: "", label: "Confirm Password", helperText: false, rules: ["isConfirm"], allow_null: false, confirm_to: "password", confirm_suffix: "password match", },
      },
      responses: {
        "fail": "Not able to update your pin"
      }
    }
  },
  routes: {
    label: "Pin Update",
    prefix: "/u/pin-update/:token"
  },
  setup
}

export default config;
