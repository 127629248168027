import React from 'react';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: "#F47920 ",
    padding: theme.spacing(3),
    textAlign: 'left',
    color: "black",
    // marginTop: 15,
    // marginBottom: 1,
    // width: "374px!important",
    height: "24px!important",
    paddingTop: "14px!important",
    paddingBottom: "21px!important",
    boxShadow: "0px!important",
    borderRadius: "0px!important"
  },
}));
/* CONSTANTS */

const wrapper = props => {

  /* mui */
  const classes = useStyles();
  /* mui */

  return <Paper className={classes.paper}>
    { props.children }
  </Paper>
}

export default wrapper;
