import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { Skeletons } from '../../lib-atomic';

const Loader = props => {
  return <Fragment>
   <Typography align="center">
      <Skeletons.Spinner />
    </Typography>
  </Fragment>
}

export default Loader;
