import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "login";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'moduleCode': setup.setup.thor.moduleCode,
          'Authorization': null,
        },
        endpoints: {
          resetPin: {
            url: setup.setup.api.countdown_v1.url[environment] + '/password/',
            method: 'POST'
          },
          captcha: {
            doc: 'https://countdown-uat.tr4ns.com/recaptcha',
            url: setup.setup.api.countdown_v1.url.captcha + '/recaptcha?token=',
            method: 'GET'
          }
        }
      },
      objects: {
        emailAddress: { name: "emailAddress", initial: "", label: "Email", helperText: false, rules: ["isEmail"],validationName:"registered" },
      }      
    }
  },
  routes: {
    label: "Pin Reset Admin",
    prefix: "/u/pin-reset"
  },
  setup
}

export default config;
