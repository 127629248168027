import React, {useEffect, useState} from 'react'
import {Link, Redirect} from 'react-router-dom';
import {observer, useObserver} from 'mobx-react-lite'

/* mui */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import {Buttons} from '../../lib-atomic';
import {ModulePaperCreditPlusWrapper, ModulePaperCreditMinusWrapper} from '../../lib-atomic-design-section';
import {LocalStorage} from '../../lib-atomic-functions';
/* packages */

/* sections */
import Loader from '../loader';
import TransactionTopup from '../info/topup';
import TransactionRedemption from '../info/redemption';
import Bonus from '../info/bonus';
import TransactionRedemptionRev from '../info/redRev';
import TransactionTopupRev from '../info/topupRev';
import BonusLocked from '../info/bonusLocked';
import BonusUnlocked from '../info/bonusUnocked';
import RefundTransaction from '../info/refundTransaction'
/* sections */
import axios from 'axios'
/* functions  */
import {AccountTransaction} from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';

const index = observer((props) => {
    const [query,
        setQuery] = useState('redux');
    const [data,
        setData] = useState();
    // const [filtter, setfiltter] = React.useState(false);

    console.log("query", props);
    const LS = LocalStorage.getLS();
    const {login} = LS;
    if (!login) 
        return <Redirect to='/u/logout'/>

    const {userId, token} = login;
    var methodData;
    if (props.query) {
        methodData = {
            token: token,
            query: props.query
        }

        // console.log("accountTransactionContext", accountTransactionContext.data);
    } else {
        methodData = {
            token,
            query: "?member=" + userId + "&page=0&pageSize=25"
        }
    }

    /* state */
    const {accountTransactionContext, accountTransactionMethod} = AccountTransaction({CONFIG});

    useEffect(() => {
        accountTransactionMethod(methodData);
    }, []);

    useEffect(() => {
        if (accountTransactionContext.expireToken) 
            props.history.push('/u/landing-token-expire');
        }
    , [accountTransactionContext.expireToken]);

    useEffect(() => {
        if (props.query) 
            accountTransactionMethod(methodData);
        }
    , [props.query]);

    /* state */

    if (accountTransactionContext.loading) 
        return <Loader/>
    if (!accountTransactionContext.data) 
        return <Loader/>
    if (accountTransactionContext.expireToken) 
        return <Loader/> // redirect
    
    /* data props */
    const {transactions} = accountTransactionContext.data;

    /* data props */

    return transactions.map((transaction, index) => {

        if (transaction.transactionType.id == 85) 
            return <BonusLocked {...transaction}/>
        if (transaction.transactionType.id == 86) 
            return <BonusUnlocked {...transaction}/>
        if (transaction.transactionType.id == 20) 
            return <Bonus {...transaction}/>
        if (transaction.transactionType.id == 4) 
            return <TransactionRedemption {...transaction}/>
        if (transaction.transactionType.id == 9) 
            return <TransactionTopup {...transaction}/>
        if (transaction.transactionType.id == 19) 
            return <RefundTransaction {...transaction}/>
        if (transaction.transactionType.id == -4) 
            return <TransactionRedemptionRev {...transaction}/>
        if (transaction.transactionType.id == -9) 
            return <TransactionTopupRev {...transaction}/>

    });

});

export default index;
