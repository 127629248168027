import React from "react"

import './style.css';

const xlarge = props => {
  return <div >
    <img style={{height:'100%', width:'100%'}} href="https://countdown-mj.s3-ap-southeast-2.amazonaws.com/assets/countdown/spinner-icon-gif-10.jpg"></img>
  </div>
}

export default xlarge;
