import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Account Balance";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    account: {
      api: {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': null
        },
        endpoints: {
          balance: {
            doc: 'https://countdown-uat.txweb.site/members/balance',
            url: setup.setup.api.countdown_v1.url[environment] + '/members/balance',
            method: 'GET'
          }
        }
      }
    }
  },
  routes: {
    label: "Balance Info",
    prefix: "/account/balance/info"
  },
  setup
}

export default config;
