import React from "react";

import Imports from './imports';

const Index = (props) => {
  return <div>
    <Imports />
  </div>
}

export default Index;
