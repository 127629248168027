import React from 'react';

/* mui */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
/* mui */

/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    textAlign: 'left',
    color: "#black",
    marginTop: 15,
    // marginBottom: 15,
    // height: "88px !important",
    // width: "375px !important"
  },
}));
/* CONSTANTS */

const wrapper = props => {

  /* mui */
  const classes = useStyles();
  /* mui */

  return <Paper className={classes.paper}>
    { props.children }
  </Paper>
}

export default wrapper;
