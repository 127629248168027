import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite';

/* packages */
/* packages */

/* stores */
/* stores */

import CONFIG from '../config';

const index = observer((props) => {

  return <div>
    Welcome To Demo
  </div>

});

export default index;
