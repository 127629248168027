import React from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Moment from 'react-moment';

/* mui */
import Typography from '@material-ui/core/Typography';
import FaceIcon from '@material-ui/icons/Face';
/* mui */

/* packages */
import { ModulePaperInfoWrapper } from '../../lib-atomic-design-section';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

const index = observer((props) => {

  

  const LS = LocalStorage.getLS();
  const { login, detail } = LS;
  if (!login || !detail) return <Redirect to='/u/logout' />
 
  const { userId, username } = login;
  const { firstName, lastName, enrolmentDate } = detail.traderDetails;
 

  return <ModulePaperInfoWrapper >
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" align="right" style={{position: "relative"}}>
      <Link to="/account/details" style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" style={{position: "absolute", right: 0,  }}>
        {/* <FaceIcon color="primary" fontSize="large" /> */}
      </Link>
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h5">
      <b>Welcome</b>
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}}variant="h5">
      <b>{ firstName } { lastName }</b>
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h6" noWrap>
      <small>Account Number</small>
    </Typography>
    <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h6" noWrap>
      { userId }
    </Typography>
    {/* <Typography variant="caption" noWrap>
      <small>Card number</small>
    </Typography>
    <Typography variant="subtitle2" noWrap>
      { username }
    </Typography> */}
    {/*
      <Typography variant="caption" noWrap>
        <small>REGISTRATION DATE</small>
      </Typography>
      <Typography variant="subtitle2" noWrap>
        <Moment format="DD/MM/YYYY">{enrolmentDate}</Moment>
      </Typography>
    */}
  </ModulePaperInfoWrapper>

})

export default index;
