import Small from './small';
import Medium from './medium';
import Large from './large';
import Xlarge from './xlarge';
import Spinner from './spinner';

export default {
  Small,
  Medium,
  Large,
  Xlarge,
  Spinner
}
