import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { Buttons } from '../../lib-atomic';
import { LocalStorage,SessionStorage } from '../../lib-atomic-functions';
/* packages */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
/* sections */
import Loader from '../loader';
/* sections */

/* functions  */
import { AccountRegisterActivation } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';

/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
const LANDINGGRAPHICS = CONFIG.setup.assets.landing.registration_exist;
/* CONSTANTS */
const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "auto"
  },
  square: {
    width: 10,
    height: 10,
    color: 'black'
}
}));
const index = observer((props) => {
  const LS = LocalStorage.getLS();
  const { detail } = LS;
  // LocalStorage.removeLS();

const userName = detail.traderDetails.firstName;
const lastName = detail.traderDetails.lastName;
const trader = detail.traderId;

var res = userName.charAt(0);
var last = lastName.slice(0, 11);


  /* activation responses */
 
  /* activation responses */
  const classes = useStyles();

  const linkButton = { size: "small" }


  return <Fragment>
    
    <Typography style={{height: "87px", marginTop: "19px"}}  align="center" >
    <img  className={classes.paper} src={LOGO} />
    </Typography>
    <ModulePaperWrapper>
    <br/>
      <br/>
      <br/>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" gutterBottom>
      How to top up your card 
      </Typography>
      <br/>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2">
     You can save for Christmas by topping up your Christmas Club account directly from your bank account. Follow the instructions below to set up a one-off or automatic payment from your online banking portal.
      </Typography>
     
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2">
     
     
      <br/>
     <strong>1. Log into your online banking</strong> <br/>
     <span>Go to your online banking portal as you normally would when setting up a new one-off or automatic payment for a payee.</span> 
      <br/>
      <br/>
      <strong>2. Select "Woolworths Christmas Club" as payee</strong> <br/>
      <span>You can find us in your bank’s list of verified/registered companies when searching for “Woolworths Christmas Club”.</span> 
      <br/>
      <br/>
      <strong>3. Enter the details that will show on the payee’s statement</strong> <br/>
      <span>By providing the correct payment reference, we will be able to link your payment to your Christmas Club account.</span> 
      <br/>
      <br/>
      &nbsp; &nbsp; &#9632; &nbsp;	<b> Particulars:</b> <span style={{    fontWeight: '700' ,color: '#007837'}}>{res+last}</span>
      <br/>
      &nbsp;	&nbsp; &#9632; &nbsp;<b>   Code:</b> <i >leave this field blank</i> <br/>
      &nbsp; &nbsp; &#9632; &nbsp;	<b>  Reference:</b> <span style={{    fontWeight: '700' ,color: '#007837'}}>{trader}</span><br/>
      <br/>
      <strong>4. Check that the payment details are correct</strong> <br/>
      <span>This is super important. Unfortunately, we won’t be able to process your payment if you have not provided the correct account number as payment reference.</span> 
      <br/>
      <br/>
      We recommend that you keep an eye on your Christmas Club balance and your bank account when you top up your Christmas Club account for the first time to be sure your payment has been processed correctly. In the unlikely case that your payment could not be processed, you will receive a refund from us to your bank account within 4 business days.
      </Typography>
      <br/>
    
      
    </ModulePaperWrapper>
  </Fragment>

})

export default index;
