const environment = process.env.NODE_ENV;
const project = "bulk ordering container";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  routes: {
    label: "Customer",
    prefix: "/customer/"
  }
}

export default config;
