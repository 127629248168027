import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

/* packages */
import { ModulePaperWrapper } from '../../lib-atomic-design-section';
import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* sections */
import Loader from '../loader';
/* sections */

import CONFIG from '../config';

/* CONSTANTS */
const LOGO = CONFIG.setup.assets.logo;
/* CONSTANTS */

const index = observer((props) => {

  /* states */
  useEffect(() => {
    handleRedirect();
  }, []);
  /* states */

  const handleRedirect = () => {
    LocalStorage.removeLS();
    props.history.push('/u/landing-login-logout');
  }

  return <Fragment>
    <Typography align="center" gutterBottom>
      <img src={LOGO} />
    </Typography>
    <Loader />
  </Fragment>

})

export default index;
