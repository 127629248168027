import React from "react"

import './spinner.css';

const spinner = props => {
 return <div class="lds-roller">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
 </div>

}

export default spinner;
