import React from "react";

/* packages */
import Packages from '../packages';
const {  Modules } = Packages;
/* packages */

const Atom = props => {
  let Applications = [];
  for (const module in Modules) {
    const { Launcher } = Modules[module];
    Applications.push(Launcher);
  }
  return Applications.map((Launcher, i) => {
    return <div className="mb-3" sm="12" md="4" lg="3" xl="2">
      <Launcher {...props} key={i} />
    </div>
  });
}

export default Atom;
