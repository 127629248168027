import React from 'react'
import Moment from 'react-moment';

/* mui */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
/* mui */
import NumberFormat from 'react-number-format';
/* packages */
import { ModulePaperCreditPlusWrapper } from '../../lib-atomic-design-section';
/* packages */

const index = ({storeName, transactionDate, creditValue, processedDate,purchaseValue}) => {

  var value = Number(purchaseValue);

  var value2 =  value.toFixed(2) 
  const v =  processedDate.substring(0, 20);
 

  return <ModulePaperCreditPlusWrapper >
    <Grid container spacing={2}>
      <Grid item xs>
        <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} variant="subtitle2" noWrap>
        <b>Top up reversal</b> <br/>
          <small><Moment style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} format="DD/MM/YYYY HH:mm">{ v }</Moment></small><br/>
        </Typography>
      </Grid>
      <Grid item >
        <Typography style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif', fontWeight: 'bold'}} variant="subtitle1">
          {/* <b>${ value2 }</b> */}
          <NumberFormat value={value2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </Typography>
      </Grid>
    </Grid>
  </ModulePaperCreditPlusWrapper>

}

export default index;
