import React from 'react'

/* mui */
import Typography from '@material-ui/core/Typography';
/* mui */

const ttile = props => {
  return <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="h4" align="center" gutterBottom>
    <b>Please Create PIN</b>
  </Typography>
}

export default ttile;
